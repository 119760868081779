import React from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr";
import Journalier from "../components/Journalier";
import { Transaction } from "../model/Transaction";
import { Investissement } from "../model/Investissement";
import { ImpotType } from "../types/model";
import { FormInstance } from "antd";

dayjs.locale("fr");

export function formatMoney(value: number) {
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".") + " Ar";
}

export function formatNumber(value: number) {
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export const formatFrequency = (
  item: Transaction | Investissement,
  value: string,
  date?: string
) => {
  switch (value) {
    case "daily":
      return <Journalier payload={item} />;
    case "weekly":
      return "Hebdomadaire";
    case "monthly":
      return "Mensuel";
    case "once":
      return `Ponctuel (${dayjs(date).format("DD MMM YYYY")})`;
    default:
      return "";
  }
};

export function formatImpot(
  value: number,
  currency: ImpotType,
  amount: number
) {
  let result: string = "";
  if (currency === "pourcentage") {
    const val = Math.floor((value / 100) * amount);
    result = `${formatMoney(val)} (${value}%)`;
  } else {
    result = formatMoney(value);
  }
  return result;
}

export const formatFormDataDate = (date: Dayjs) => {
  return date ? date.format("YYYY-MM-DD") : "";
};

export const asset = (path: string) => {
  return `${process.env.REACT_APP_API_ASSET_URL}${path.replace("public/", "")}`;
};

export const formatIncomeFormData = (form: FormInstance<Transaction>) => {
  const pj =
    form.getFieldValue("pj") && form.getFieldValue("pj").file
      ? form.getFieldValue("pj").file.originFileObj
      : null;

  return {
    ...form.getFieldsValue(),
    pj,
    datefrequency: formatFormDataDate(form.getFieldValue("datefrequency")),
    begin_at: formatFormDataDate(form.getFieldValue("begin_at")),
    end_at: formatFormDataDate(form.getFieldValue("end_at")),
  };
};

export const formatOutcomeFormData = (form: FormInstance<Transaction>) => {
  const pj =
    form.getFieldValue("pj") && form.getFieldValue("pj").file
      ? form.getFieldValue("pj").file.originFileObj
      : null;
  return {
    ...form.getFieldsValue(),
    datefrequency: formatFormDataDate(form.getFieldValue("datefrequency")),
    pj,
    begin_at: formatFormDataDate(form.getFieldValue("begin_at")),
    end_at: formatFormDataDate(form.getFieldValue("end_at")),
  };
};

export const formatInvestFormData = (form: FormInstance<Investissement>) => ({
  ...form.getFieldsValue(),
  datefrequency: formatFormDataDate(form.getFieldValue("datefrequency")),
  begin_at: formatFormDataDate(form.getFieldValue("begin_at")),
  end_at: formatFormDataDate(form.getFieldValue("end_at")),
});

export const formatDateData = (date?: string) => {
  return date ? dayjs(date) : dayjs();
};
