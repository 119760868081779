import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Modal, Row, Statistic } from "antd";
import React from "react";
import ReactECharts from "echarts-for-react";
import { solde_details } from "../../data/incomes";
import CountUp from "react-countup";
import { valueType } from "antd/es/statistic/utils";

type Props = {
  solde: number;
  revenues: number;
  depenses: number;
  impots: number;
};

function Solde({ solde, revenues, depenses, impots }: Props) {
  const [open, setOpen] = React.useState<boolean>(false);

  const formatter = (value: valueType) => (
    <CountUp end={value as number} separator=" " suffix=" Ar" />
  );

  return (
    <>
      <Button
        type="text"
        icon={<InfoCircleOutlined />}
        onClick={() => setOpen(true)}
      >
        Détails
      </Button>
      <Modal
        open={open}
        width={800}
        title={"Détails sur le calcul du solde actuel"}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Alert
          message="Le solde est calculé en se basant sur le solde initial du mois ou le solde de début, avec les données enregistrées entre le début du mois et le jour actuel."
          type="info"
          showIcon
          closable
        />
        <Row gutter={16} style={{ marginTop: 22 }}>
          <Col span={12}>
            <Statistic
              title="Solde au début du mois"
              value={solde}
              precision={2}
              formatter={formatter}
              style={{ marginBottom: 16 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Revenues jusqu'à aujourd'hui"
              value={revenues}
              precision={2}
              formatter={formatter}
              style={{ marginBottom: 16 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Dépenses jusqu'à aujourd'hui"
              value={depenses}
              precision={2}
              formatter={formatter}
              style={{ marginBottom: 16 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Impôts à payer"
              value={impots}
              precision={2}
              formatter={formatter}
              style={{ marginBottom: 16 }}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 22 }}>
          <Col span={24}>
            <div style={{ padding: "20px 0" }}>
              <ReactECharts
                option={solde_details({
                  depenses,
                  impots,
                  revenues,
                  solde,
                })}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 450 }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default Solde;
