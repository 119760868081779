import {
  BarChartOutlined,
  MoneyCollectOutlined,
  PieChartOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Statistic } from "antd";
import React from "react";
import CountUp from "react-countup";
import { getDashboardStats } from "../../services/SoldeService";

type Props = {};

type DataType = {
  solde: number;
  revenues: number;
  depenses: number;
  balance: number;
};

function Cards({}: Props) {
  const [data, setData] = React.useState<DataType>({
    solde: 0,
    revenues: 0,
    depenses: 0,
    balance: 0,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getData = () => {
    setIsLoading(true);
    getDashboardStats()
      .then((res) => {
        if (res && res.data) {
          setData(res.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Row gutter={16}>
      {isLoading ? (
        <Col span={24}>
          <Card style={{ marginBottom: 16 }}>
            <Skeleton active />
          </Card>
        </Col>
      ) : (
        <>
          <Col lg={6} md={12} span={24}>
            <Card bordered={false} style={{ marginBottom: 16 }}>
              <Statistic
                title="Solde actuel"
                value={11.28}
                precision={2}
                prefix={<BarChartOutlined />}
                valueRender={() => (
                  <CountUp
                    end={data.solde}
                    separator=" "
                    suffix=" Ar"
                    duration={2}
                  />
                )}
              />
            </Card>
          </Col>
          <Col lg={6} md={12} span={24}>
            <Card bordered={false} style={{ marginBottom: 16 }}>
              <Statistic
                title="Revenues ce mois"
                value={11.28}
                precision={2}
                prefix={<MoneyCollectOutlined />}
                valueRender={() => (
                  <CountUp
                    end={data.revenues}
                    separator=" "
                    suffix=" Ar"
                    duration={2}
                  />
                )}
              />
            </Card>
          </Col>
          <Col lg={6} md={12} span={24} style={{ marginBottom: 16 }}>
            <Card bordered={false}>
              <Statistic
                title="Dépenses ce mois"
                value={11.28}
                precision={2}
                prefix={<PieChartOutlined />}
                valueRender={() => (
                  <CountUp
                    end={data.depenses}
                    separator=" "
                    suffix=" Ar"
                    duration={2}
                  />
                )}
              />
            </Card>
          </Col>
          <Col lg={6} md={12} span={24} style={{ marginBottom: 16 }}>
            <Card bordered={false}>
              <Statistic
                title="Balance"
                value={11.28}
                precision={2}
                prefix={<SwapOutlined />}
                valueRender={() => (
                  <CountUp
                    end={data.balance}
                    separator=" "
                    suffix=" Ar"
                    duration={2}
                  />
                )}
              />
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
}

export default Cards;
