import { Investissement } from "../model/Investissement";
import { DailyType } from "../types/model";
import { createFormData } from "../utils/services";
import axiosInstance from "./AxiosInstance";

export function getInvests() {
  return axiosInstance.get(`invest/`);
}

export function createInvestissement(data: Partial<Investissement>) {
  return axiosInstance.post(`invest/add`, createFormData(data));
}

export function updateInvestissement(
  id: string | number,
  data: Partial<Investissement>
) {
  return axiosInstance.post(`invest/update/${id}`, createFormData(data));
}

export function updateActiveInvestissement(
  id: string | number,
  active: boolean
) {
  return axiosInstance.post(
    `invest/update-active/${id}`,
    createFormData({ active: active ? 1 : 0 })
  );
}

export function updateDailyOptionInvestissement(
  id: string | number,
  option: DailyType
) {
  return axiosInstance.post(
    `invest/update-daily/${id}`,
    createFormData({ daily: option })
  );
}

export function deleteInvestissement(id: number) {
  return axiosInstance.post(`invest/delete/${id}`);
}
