import React from "react";
import { getIncomes, getIncomesStats } from "../services/TransactionService";
import { IncomeStats, Transaction } from "../model/Transaction";
import { message } from "antd";
import { useOutcome } from "./OutcomeContext";
import { useSetting } from "./SettingContext";

type ContextType = {
  incomes: Transaction[];
  selected: Transaction[];
  stats: IncomeStats | null;
  loading: boolean;
  reloadData: () => void;
  setSelected: (selected: Transaction[]) => void;
};

const IncomeContext = React.createContext<ContextType>({
  incomes: [],
  selected: [],
  loading: true,
  stats: null,
  reloadData: () => {},
  setSelected: (selected: Transaction[]) => {},
});

const IncomeProvider = ({ children }: { children: React.ReactNode }) => {
  const [incomes, setIncomes] = React.useState<Transaction[]>([]);
  const [selected, setSelected] = React.useState<Transaction[]>([]);
  const [stats, setStats] = React.useState<IncomeStats | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { outcomes } = useOutcome();
  const { state: setting } = useSetting();

  const getData = () => {
    getIncomes()
      .then((response) => {
        if (response.data) {
          setIncomes(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentMonth = () => {
    return getIncomesStats()
      .then((response) => {
        if (response && response.data) {
          setStats(response.data);
        }
      })
      .catch((error) => {
        message.error(
          "Erreur lors de la récupération des revenus du mois courant"
        );
      });
  };

  React.useEffect(() => {
    getCurrentMonth();
  }, [incomes, outcomes, setting]);

  return (
    <IncomeContext.Provider
      value={{
        incomes,
        selected,
        stats,
        loading,
        reloadData: getData,
        setSelected,
      }}
    >
      {children}
    </IncomeContext.Provider>
  );
};

const useIncome = () => React.useContext(IncomeContext);

export { IncomeProvider, useIncome };
