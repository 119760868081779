import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  message,
} from "antd";
import React from "react";
import { Transaction } from "../../model/Transaction";
import { rules } from "../../constants";
import { useAction } from "../../contexts/ActionContext";
import { TransactionFrequency, TransactionType } from "../../types/model";

type Props = {};

const { Option } = Select;

const defaultInput: Transaction = {
  active: 1,
  amount: 0,
  frequency: "monthly",
  description: "",
  reason: "",
  datefrequency: "",
  type: "epargne",
};

const fequencyOptions: { label: string; value: TransactionFrequency }[] = [
  { label: "Journalier", value: "daily" },
  { label: "Hebdomadaire", value: "weekly" },
  { label: "Mensuel", value: "monthly" },
  { label: "Ponctuel", value: "once" },
];

const TypeOptions: { label: string; value: TransactionType }[] = [
  { label: "Revenu", value: "revenu" },
  { label: "Dépense", value: "depense" },
  { label: "Economie", value: "epargne" },
];

function AddEconomy({}: Props) {
  const [form] = Form.useForm();
  const [isOnce, setIsOnce] = React.useState<boolean>(false);
  const { openAddEconomy, setOpenAddEconomy } = useAction();

  const checkFrequency = (value: TransactionFrequency) => {
    if (value === "once") {
      setIsOnce(true);
    } else {
      setIsOnce(false);
    }
  };

  const save = () => {
    message.info("Accessed here!");
    console.log(form.getFieldsValue());
  };

  return (
    <Modal
      title="Ajouter des économies"
      open={openAddEconomy}
      footer={null}
      onCancel={() => setOpenAddEconomy(false)}
      okText="Enregistrer"
      cancelText="Annuler"
    >
      <Form
        form={form}
        initialValues={{ ...defaultInput }}
        style={{ marginTop: 22 }}
        layout="vertical"
        name="control-economy"
        onFinish={save}
      >
        <Form.Item name="reason" label="Raison" rules={[rules.requiredField]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Montant (Ariary)"
          rules={[
            rules.requiredField,
            {
              min: 100,
              type: "number",
              message: "La valeur doit être au minimum 100 Ariary",
            },
          ]}
        >
          <InputNumber min={100} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Fréquence"
          rules={[rules.requiredField]}
          tooltip="L'option Ponctuel signifie appliqué  une fois"
        >
          <Select
            placeholder="Choisir parmis les fréquences"
            allowClear
            onChange={checkFrequency}
          >
            {fequencyOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOnce && (
          <Form.Item
            name="datefrequency"
            label="Appliqué sur quel date?"
            rules={[rules.requiredField]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        )}
        <Form.Item name="type" label="Type" rules={[rules.requiredField]}>
          <Select placeholder="Choisir parmis les type" disabled>
            {TypeOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Button onClick={() => setOpenAddEconomy(false)}>Annuler</Button>
            <Button htmlType="submit" type="primary">
              Enregistrer
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddEconomy;
