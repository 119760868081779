import React from "react";
import ReactECharts from "echarts-for-react";
import { Card, Skeleton } from "antd";
import { solde_explained } from "../../data/incomes";
import { getSixLastMonthSolde } from "../../services/SoldeService";

type Props = {};

type DataType = {
  labels: Array<string>;
  soldes: Array<number>;
  revenues: Array<number>;
  depenses: Array<number>;
  impots: Array<number>;
};

type DataResponseType = {
  solde: number;
  revenues: number;
  impots: number;
  depenses: number;
  month: string;
};

const default_data: DataType = {
  depenses: [320, 332, 301, 334, 390, 291],
  impots: [220, 182, 191, 234, 290, 121],
  labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  revenues: [150, 232, 201, 154, 190, 490],
  soldes: [1098, 1077, 1101, 1099, 1040, 1030],
};

export default function Incomes({}: Props) {
  const [data, setData] = React.useState<DataType>(default_data);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getData = () => {
    setIsLoading(true);
    getSixLastMonthSolde()
      .then((res) => {
        if (res && res.data) {
          setData({
            depenses: res.data.map((item: DataResponseType) => item.depenses),
            impots: res.data.map((item: DataResponseType) => item.impots),
            labels: res.data.map((item: DataResponseType) => item.month),
            revenues: res.data.map((item: DataResponseType) => item.revenues),
            soldes: res.data.map((item: DataResponseType) => item.solde),
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Card style={{ marginBottom: 16 }}>
      <h3 style={{ marginTop: 0 }}>Évolution du solde</h3>
      {isLoading ? (
        <Skeleton loading={isLoading} active />
      ) : (
        <ReactECharts
          option={solde_explained(data)}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: 450 }}
        />
      )}
    </Card>
  );
}
