import { Layout, Steps } from "antd";
import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import Pricing from "../../components/Register/Pricing";

type Props = {};

function Steppers({}: Props) {
  const [current, setCurrent] = React.useState(0);
  const { isAuthentified } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthentified) {
      navigate("/login");
    }
  }, [isAuthentified]);

  return (
    <Layout>
      <Steps
        size="default"
        current={current}
        items={[
          {
            title: "Tarification",
          },
          {
            title: "Informations complémentaires",
          },
        ]}
      />
      <div>
        <Pricing />
      </div>
    </Layout>
  );
}

export default Steppers;
