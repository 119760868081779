import { Form, InputNumber, Modal, Select, message } from "antd";
import React from "react";
import { useAction } from "../../contexts/ActionContext";
import { useAuth } from "../../contexts/AuthProvider";
import { Setting as SettingModel } from "../../model/Setting";
import { useSetting } from "../../contexts/SettingContext";
import { rules } from "../../constants";
import { currency_list, lang_list, theme_list } from "../../data/setting";
import {
  updateFirstTime,
  refreshToken,
  updateSetting,
} from "../../services/UserService";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { formatNumber } from "../../utils/data";

type Props = {};

type FieldType = Partial<SettingModel>;

const { Option } = Select;

function Setting({}: Props) {
  const [form] = Form.useForm();
  const { openSetting, setOpenSetting } = useAction();
  const { user } = useAuth();
  const { state, dispatch } = useSetting();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["token"]);

  const refreshUserData = () => {
    refreshToken(cookies.token).then((res) => {
      if (res && res.data && res.data.newToken) {
        setCookie("token", res.data.newToken, {
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 14),
        });
      }
    });
  };

  const updateSettings = () => {
    setIsLoading(true);
    updateSetting(form.getFieldsValue())
      .then(() => {
        message.success("Paramètres mis à jour");
        dispatch({ type: "update", payload: form.getFieldsValue() });
        setOpenSetting(false);
        refreshUserData();
      })
      .catch((error) => {
        message.error("Erreur lors de la mise à jour des paramètres");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (user && user.first_time) {
      setOpenSetting(true);
      updateFirstTime().then(() => {
        refreshUserData();
      });
    }
  }, [user]);

  return (
    <Modal
      title="Paramètres"
      open={openSetting}
      onCancel={() => setOpenSetting(false)}
      onOk={updateSettings}
      okText="Enregistrer"
      okButtonProps={{ loading: isLoading }}
    >
      <Form
        name="setting-form"
        layout="vertical"
        form={form}
        initialValues={state}
        autoComplete="off"
        style={{ paddingTop: 20 }}
      >
        <Form.Item<FieldType>
          label={`Solde de départ`}
          name="solde"
          rules={[
            rules.requiredField,
            { type: "number", min: 0, message: "Le solde doit être positif" },
          ]}
          tooltip={`Le solde de départ est le solde de votre compte au moment de sa création${
            state.created_at
              ? ` (${dayjs(state.created_at).format("DD MMM YYYY")})`
              : ""
          }`}
        >
          <InputNumber style={{ width: "100%" }} formatter={(value) => formatNumber(value as number)} />
        </Form.Item>

        <Form.Item<FieldType>
          label="Devise"
          name="currency"
          rules={[rules.requiredField]}
        >
          <Select placeholder="Choisir une devise" allowClear={false}>
            {currency_list.map((currency, index) => (
              <Option value={currency.value} key={index}>
                {currency.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<FieldType>
          label="Langue"
          name="lang"
          rules={[rules.requiredField]}
        >
          <Select placeholder="Choisir une langue" allowClear={false}>
            {lang_list.map((langue, index) => (
              <Option value={langue.value} key={index}>
                {langue.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<FieldType>
          label="Thème"
          name="theme"
          rules={[rules.requiredField]}
        >
          <Select placeholder="Choisir un thème" allowClear={false}>
            {theme_list.map((theme, index) => (
              <Option value={theme.value} key={index}>
                {theme.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Setting;
