export function getCookie(name: string) {
  const cookie = document.cookie;
  const cookiePrefix = `${name}=`;
  if (cookie.length > 0) {
    let cookieStart = cookie.indexOf(cookiePrefix);
    if (cookieStart !== -1) {
      cookieStart += cookiePrefix.length;
      let cookieEnd = cookie.indexOf(";", cookieStart);
      if (cookieEnd === -1) {
        cookieEnd = cookie.length;
      }
      return cookie.substring(cookieStart, cookieEnd);
    }
  }
  return "";
}

export const createFormData = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};

export const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 1000);
};
