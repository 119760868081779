import React, { useEffect } from "react";
import { Card, Popover, Skeleton, Space, Table } from "antd";
import {
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { formatFrequency, formatMoney } from "../../utils/data";
import ActiveSwitch from "./ActiveSwitch";
import Actions from "./Actions";
import { Investissement } from "../../model/Investissement";
import { useInvestissment } from "../../contexts/InvestissmentContext";
import ShowInvestissement from "./Show";
import EditInvestissement from "./Edit";

type Props = {};

type actionType = {
  type: "show" | "edit" | null;
  payload: Investissement | null;
};

const ActiveInfo = (
  <div style={{ display: "flex", gap: 8 }}>
    <ExclamationCircleFilled style={{ color: "#1677ff" }} />
    <span>
      Quand une ligne est <strong>active</strong>, elle est prise en compte dans
      les statistiques.
    </span>
  </div>
);

function DataTable({}: Props) {
  const { invests, loading } = useInvestissment();
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [openShow, setOpenShow] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<actionType>({
    type: null,
    payload: null,
  });

  const edit = (item: Investissement) => {
    setAction({
      type: "edit",
      payload: item,
    });
  };

  const show = (item: Investissement) => {
    setAction({
      type: "show",
      payload: item,
    });
  };

  const columns: ColumnsType<Investissement> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <strong>IVST-{text}</strong>,
    },
    {
      title: "Raison",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Montant",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <strong>{formatMoney(text)}</strong>,
    },
    {
      title: "Fréquence",
      key: "frequency",
      dataIndex: "frequency",
      render: (_, record) => {
        return (
          <span>
            {formatFrequency(record, _, record.datefrequency)}
          </span>
        );
      },
    },
    {
      title: (
        <Space size={"small"}>
          <span>Actif</span>
          <Popover content={ActiveInfo} trigger={"hover"}>
            <QuestionCircleOutlined style={{ color: "#8a8a8a" }} />
          </Popover>
        </Space>
      ),
      key: "active",
      dataIndex: "active",
      filtered: true,
      render: (_, record) => <ActiveSwitch item={record} />,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => <Actions edit={edit} show={show} item={record} />,
    },
  ];

  useEffect(() => {
    if (action.payload) {
      if (action.type === "edit") {
        setOpenEdit(true);
      } else if (action.type === "show") {
        setOpenShow(true);
      }
    }
  }, [action]);

  return (
    <>
      {action.payload && (
        <>
          <ShowInvestissement
            item={action.payload}
            onClose={() => setOpenShow(false)}
            open={openShow}
          />
          <EditInvestissement
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            invest={action.payload}
          />
        </>
      )}

      {loading ? (
        <Card>
          <Skeleton active />
        </Card>
      ) : (
        <Table
          columns={columns}
          loading={loading}
          dataSource={invests.map((d) => ({ ...d, key: d.id }))}
        />
      )}
    </>
  );
}

export default DataTable;
