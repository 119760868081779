import React, { useState } from 'react';
import {
  BankOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  InfoCircleOutlined,
  PieChartOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import Actions from '../components/Actions';

import logo from '../assets/images/euro.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { ActionProvider } from '../contexts/ActionContext';
import { IncomeProvider } from '../contexts/IncomeContext';
import { OutcomeProvider } from '../contexts/OutcomeContext';
import { InvestissmentProvider } from '../contexts/InvestissmentContext';
import { useAuth } from '../contexts/AuthProvider';
import Setting from '../components/Setting/Setting';

const { Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const AppLayout : React.FC= () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { isAuthentified, user } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick: () => navigate(`/admin${path}`),
    } as MenuItem;
  }
  
  const items: MenuItem[] = [
    getItem('Tableau de bord', '/dashboard', "/dashboard", <PieChartOutlined />),
    getItem('Revenus', '/incomes', "/incomes", <PlusSquareOutlined />),
    getItem('Dépenses', '/outcomes', "/outcomes", <DollarCircleOutlined />),
    getItem('Investissement', '/invests', "/invests", <BankOutlined />),
    getItem('Calendrier', '/calendar', "/calendar", <CalendarOutlined />),
    getItem('À propos', '/about', "/about", <InfoCircleOutlined />),
  ];

  React.useEffect(() => {
    if (!isAuthentified) {
      navigate("/login");
    }
  }, [isAuthentified]);

  React.useEffect(() => {
    console.log(user)
    if (user && !user.type) {
      navigate("/create-account");
    }
  }, [user]);

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider
        collapsible 
        collapsed={collapsed} 
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
      >
        <div className="demo-logo-vertical">
          <img src={logo} alt="Logo" style={{ width: 28 }} />
          {
            collapsed ? null : (
              <span>Monkey Finance</span>
            )
          }
        </div>
        <Menu theme="dark" defaultSelectedKeys={[location.pathname.replace("admin/", "")]} mode="inline" items={items} />
      </Sider>
      <Layout>
        <ActionProvider>
          <OutcomeProvider>
            <IncomeProvider>
              <Nav collapsed={collapsed} setCollapsed={setCollapsed} />
              <Setting />
              <Content style={{ margin: '0 16px' }}>
                <InvestissmentProvider>
                  <Outlet />
                  <Actions />
                </InvestissmentProvider>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                Monkey Finance ©2023 Created by Tonny Herison
              </Footer>
            </IncomeProvider>
          </OutcomeProvider>
        </ActionProvider>
      </Layout>
    </Layout>
  );
};

export default AppLayout;