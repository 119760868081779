import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, message } from "antd";
import React from "react";
import { useInvestissment } from "../../contexts/InvestissmentContext";
import { Investissement } from "../../model/Investissement";
import { deleteInvestissement } from "../../services/InvestissementService";

type Props = {
  item: Investissement;
  show: (item: Investissement) => void;
  edit: (item: Investissement) => void;
};

function Actions({ item, show, edit }: Props) {
  const { reloadData } = useInvestissment();
  const [isRemoving, setIsRemoving] = React.useState<boolean>(false);

  const remove = (id: number) => {
    setIsRemoving(true);
    deleteInvestissement(id).then(() => {
      reloadData();
      setIsRemoving(false);
      message.success("Ligne supprimée");
    });
  };

  return (
    <Space size="small">
      <Button
        type="default"
        icon={<EyeOutlined />}
        size={"middle"}
        onClick={() => show(item)}
      />
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={"middle"}
        onClick={() => edit(item)}
      />
      <Popconfirm
        title="Supprimer la ligne ?"
        description="Cette action est irréversible."
        onConfirm={() => item.id && remove(item.id)}
        okText="Oui"
        cancelText="Annuler"
        okButtonProps={{ danger: true }}
      >
        <Button
          type="default"
          danger
          icon={<DeleteOutlined />}
          size={"middle"}
          loading={isRemoving}
        />
      </Popconfirm>
    </Space>
  );
}

export default Actions;
