import {
  BankOutlined,
  DollarOutlined,
  MonitorOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { FloatButton, Space } from "antd";
import React from "react";
import AddIncome from "./Actions/AddIncome";
import AddOutcome from "./Actions/AddOutcome";
import AddEconomy from "./Actions/AddEconomy";
import AddInvest from "./Actions/AddInvest";
import { useAction } from "../contexts/ActionContext";

type Props = {};

function Actions({}: Props) {
  const {
    setOpenAddIncome,
    setOpenAddOutcome,
    setOpenAddEconomy,
    setOpenAddInvest,
  } = useAction();

  return (
    <Space align="end">
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 56 }}
        icon={<PlusOutlined />}
      >
        <FloatButton
          icon={<PlusSquareOutlined />}
          onClick={() => setOpenAddIncome(true)}
          tooltip={<div>Ajouter des revenus</div>}
        />
        <FloatButton
          icon={<DollarOutlined />}
          onClick={() => setOpenAddOutcome(true)}
          tooltip={<div>Ajouter des dépenses</div>}
        />
        <FloatButton
          icon={<BankOutlined />}
          onClick={() => setOpenAddEconomy(true)}
          tooltip={<div>Ajouter des économies</div>}
        />
        <FloatButton
          icon={<MonitorOutlined />}
          onClick={() => setOpenAddInvest(true)}
          tooltip={<div>Ajouter une investissement</div>}
        />
      </FloatButton.Group>
      <AddIncome />
      <AddOutcome />
      <AddEconomy />
      <AddInvest />
    </Space>
  );
}

export default Actions;
