import axios from "axios";
import { getCookie } from "../utils/services";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let token = "";
  if (document) {
    const cookies = document.cookie;
    if (cookies) {
      token = getCookie("token");
    }
  }

  config.headers = config.headers || {};
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = `multipart/form-data`;
  return config;
});

export default axiosInstance;
