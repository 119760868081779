import { Button, Form, Input } from "antd";
import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { rules } from "../../constants";

type Props = {};

type FieldType = {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
};

function Register({}: Props) {
  const [form] = Form.useForm();
  const { register: create_account } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onFinish = () => {
    setIsLoading(true);
    create_account(
      form.getFieldValue("name"),
      form.getFieldValue("email"),
      form.getFieldValue("password")
    ).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Form
        name="login-form"
        form={form}
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Nom"
          name="name"
          rules={[
            {
              required: true,
              message: "Entrez votre nom",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Entrez une adresse email valide",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: "Entrez votre mot de passe" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<FieldType>
          label="Confirmer le mot de passe"
          name="confirm_password"
          rules={[
            rules.requiredField,
            {
              validator(rule, value) {
                if (value !== form.getFieldValue("password")) {
                  return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Creer un compte
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default Register;
