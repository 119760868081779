import { CurrencyType, LangType, ThemeType } from "../types/model";

type CurrencyOptionType = {
  value: CurrencyType;
  label: string;
}

type LangOptionType = {
  value: LangType;
  label: string;
}

type ThemeOptionType = {
  value: ThemeType;
  label: string;
}

export const currency_list : CurrencyOptionType[] = [
  {
    value: "AR",
    label: "Ariary"
  },
  // {j
  //   value: "EUR",
  //   label: "Euro"
  // },
]

export const lang_list : LangOptionType[] = [
  {
    value: "fr",
    label: "Français"
  },
]

export const theme_list : ThemeOptionType[] = [
  {
    value: "light",
    label: "Clair"
  },
]