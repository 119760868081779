import React from 'react'
import { Link } from 'react-router-dom'

type Props = {}

function Landing({}: Props) {
  return (
    <div>
      <Link to={`/login`}>Login Bro!</Link>
    </div>
  )
}

export default Landing