import { Button, Checkbox, Form, Input, message } from "antd";
import React from "react";
import { useAuth } from "../../contexts/AuthProvider";

type Props = {};

type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};

function Login({}: Props) {
  const [form] = Form.useForm();
  const { login: authenticate } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onFinish = () => {
    setIsLoading(true);
    authenticate(form.getFieldValue("email"), form.getFieldValue("password")).then(() => {
      message.success("Vous êtes connecté");
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Form
        name="login-form"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Entrez votre adresse email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: "Entrez votre mot de passe" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<FieldType>
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>Garder mon compte connecté</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default Login;
