import React, { useEffect } from "react";
import { Card, Popover, Skeleton, Space, Table } from "antd";
import {
  ExclamationCircleFilled,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Transaction } from "../../model/Transaction";
import { formatFrequency, formatMoney } from "../../utils/data";
import EditIncome from "./Edit";
import ShowIncome from "./Show";
import ActiveSwitch from "./ActiveSwitch";
import Actions from "./Actions";
import { useOutcome } from "../../contexts/OutcomeContext";
import { frequencyOptions } from "../../constants";
import SearchFilter from "./SearchFilter";

type Props = {};

type actionType = {
  type: "show" | "edit" | null;
  payload: Transaction | null;
};

const ActiveInfo = (
  <div style={{ display: "flex", gap: 8 }}>
    <ExclamationCircleFilled style={{ color: "#1677ff" }} />
    <span>
      Quand une ligne est <strong>active</strong>, elle est prise en compte dans
      les statistiques.
    </span>
  </div>
);

function DataTable({}: Props) {
  const { outcomes, loading, setSelected } = useOutcome();
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [openShow, setOpenShow] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<actionType>({
    type: null,
    payload: null,
  });

  const edit = (income: Transaction) => {
    setAction({
      type: "edit",
      payload: income,
    });
  };

  const show = (income: Transaction) => {
    setAction({
      type: "show",
      payload: income,
    });
  };

  const columns: ColumnsType<Transaction> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <strong>DPS-{text}</strong>,
    },
    {
      title: "Raison",
      dataIndex: "reason",
      key: "reason",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <SearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearFilters}
          confirm={confirm}
          selectedKeys={selectedKeys}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.reason
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    },
    {
      title: "Montant",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (text) => <strong>{formatMoney(text)}</strong>,
    },
    {
      title: "Fréquence",
      key: "frequency",
      dataIndex: "frequency",
      filters: frequencyOptions.map((item) => ({
        text: item.label,
        value: item.value,
      })),
      onFilter: (value: any, record) => record.frequency.indexOf(value) === 0,
      render: (_, record) => {
        return <span>{formatFrequency(record, _, record.datefrequency)}</span>;
      },
    },
    {
      title: (
        <Space size={"small"}>
          <span>Actif</span>
          <Popover content={ActiveInfo} trigger={"hover"}>
            <QuestionCircleOutlined style={{ color: "#8a8a8a" }} />
          </Popover>
        </Space>
      ),
      key: "active",
      dataIndex: "active",
      filters: [
        {
          text: "Actif",
          value: 1,
        },
        {
          text: "Inactif",
          value: 0,
        },
      ],
      onFilter: (value: any, record) => record.active === value,
      render: (_, record) => <ActiveSwitch transaction={record} />,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Actions edit={edit} show={show} transaction={record} />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Transaction[]) => {
      setSelected(selectedRows);
    },
  };

  useEffect(() => {
    if (action.payload) {
      if (action.type === "edit") {
        setOpenEdit(true);
      } else if (action.type === "show") {
        setOpenShow(true);
      }
    }
  }, [action]);

  return (
    <>
      {action.payload && (
        <>
          <ShowIncome
            outcome={action.payload}
            onClose={() => setOpenShow(false)}
            open={openShow}
          />
          <EditIncome
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            outcome={action.payload}
          />
        </>
      )}

      {loading ? (
        <Card>
          <Skeleton active />
        </Card>
      ) : (
        <Card>
          <h2 style={{ marginTop: 0 }}>Liste des dépenses</h2>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            loading={loading}
            dataSource={outcomes.map((d) => ({ ...d, key: d.id }))}
          />
        </Card>
      )}
    </>
  );
}

export default DataTable;
