import { SettingOutlined } from "@ant-design/icons";
import { Modal, Radio, Space, Tooltip, message } from "antd";
import React, { useEffect } from "react";
import { Transaction } from "../model/Transaction";
import { Investissement } from "../model/Investissement";
import { updateDailyOptionTransaction } from "../services/TransactionService";
import { DailyType } from "../types/model";
import { updateDailyOptionInvestissement } from "../services/InvestissementService";
import { useIncome } from "../contexts/IncomeContext";
import { useOutcome } from "../contexts/OutcomeContext";
import { useInvestissment } from "../contexts/InvestissmentContext";

type Props = {
  payload: Transaction | Investissement;
};

//function to check if the payload is a transaction
function isTransaction(
  payload: Transaction | Investissement
): payload is Transaction {
  return (payload as Transaction).type !== undefined;
}

export default function Journalier({ payload }: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<DailyType>(
    payload.daily ? payload.daily : "no-weekend"
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { reloadData: reloadIncomes } = useIncome();
  const { reloadData: reloadOutcomes } = useOutcome();
  const { reloadData: reloadInvestissments } = useInvestissment();

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const save = () => {
    if (payload.id) {
      setIsLoading(true);
      if (isTransaction(payload)) {
        updateDailyOptionTransaction(payload.id, value)
          .then(() => {
            setOpen(false);
            message.success("Information mise à jour");
            switch (payload.type) {
              case "revenu":
                reloadIncomes();
                break;
              case "depense":
                reloadOutcomes();
                break;
              default:
                break;
            }
          })
          .catch(() => {
            message.error("Une erreur est survenue");
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        updateDailyOptionInvestissement(payload.id, value)
          .then(() => {
            setOpen(false);
            message.success("Information mise à jour");
            reloadInvestissments();
          })
          .catch(() => {
            message.error("Une erreur est survenue");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setValue(payload.daily ? payload.daily : "no-weekend");
  }, [payload]);

  return (
    <>
      <span style={{ display: "flex", alignItems: "center", gap: 7 }}>
        Journalier{" "}
        <SettingOutlined
          style={{ color: "#898989", cursor: "pointer" }}
          onClick={() => setOpen(true)}
        />
      </span>
      <Modal
        title="Paramètres"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={save}
        okText="Enregistrer"
        okButtonProps={{ loading: isLoading }}
        cancelText="Annuler"
      >
        <h4>
          <Space>
            <span>Choisir parmis la liste</span>
            <Tooltip
              title="Les weekends pris en compte sont le samedi et le dimanche!"
              trigger="hover"
            >
              <i
                className="fa-regular fa-circle-question"
                style={{ cursor: "pointer" }}
              ></i>
            </Tooltip>
          </Space>
        </h4>
        <div>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={"with-weekend"}>
                Prendre en compte les weekend
              </Radio>
              <Radio value={"no-weekend"}>
                Ne pas prendre en compte les weekend
              </Radio>
              <Radio value={"only-weekend"}>Uniquement les weekend</Radio>
            </Space>
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
}
