import { RiseOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Modal, Row, Spin, Statistic } from "antd";
import React from "react";
import ReactECharts from "echarts-for-react";
import { formatMoney } from "../../utils/data";
import { projection } from "../../data/incomes";
import { getNextSixMonthsIncomes } from "../../services/TransactionService";
import { useIncome } from "../../contexts/IncomeContext";
import { useOutcome } from "../../contexts/OutcomeContext";
import { useSetting } from "../../contexts/SettingContext";

type Props = {};

function IncomeProjection({}: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<any>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { incomes } = useIncome();
  const { outcomes } = useOutcome();
  const { state } = useSetting();

  const getData = () => {
    setIsLoading(true);
    getNextSixMonthsIncomes()
      .then((res) => {
        if (res && res.data) {
          setData(res.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, [incomes, outcomes, state]);

  return (
    <>
      <Button
        type="text"
        icon={<RiseOutlined />}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        width={800}
        title={"Projection sur les 6 prochains mois"}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Alert
          message="Toutes les informations affichées ici sont calculées à partir des revenues renseignés dans la liste."
          type="info"
          showIcon
          closable
        />
        {isLoading ? (
          <Spin>
            <div style={{ width: "100%", height: 100 }} />
          </Spin>
        ) : (
          <Row gutter={16} style={{ marginTop: 22 }}>
            {/* <Col span={6}>
              <Statistic
                title="Total des revenues"
                value={9300}
                formatter={(value) => formatMoney(value as number)}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="Revenue maximum"
                value={9300}
                formatter={(value) => formatMoney(value as number)}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="Total des impôts à payer"
                value={9300}
                formatter={(value) => formatMoney(value as number)}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="Solde restant après 6 mois"
                value={9300}
                formatter={(value) => formatMoney(value as number)}
              />
            </Col> */}
            <Col span={24}>
              <div style={{ padding: "20px 0" }}>
                <ReactECharts
                  option={projection(
                    data.map((item) => item.month),
                    data.map((item) => item.incomes),
                    data.map((item) => item.balance)
                  )}
                  notMerge={true}
                  lazyUpdate={true}
                  className="w-100"
                  style={{ height: 450 }}
                />
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
}

export default IncomeProjection;
