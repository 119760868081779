export const outcome_suggestions: string[] = [
  "Alimentation",
  "Logement",
  "Transport",
  "Santé",
  "Éducation",
  "Divertissement",
  "Voyages",
  "Assurance",
  "Télécommunications",
  "Épargne",
  "Shopping",
  "Factures",
  "Loisirs",
  "Restaurants",
  "Café",
  "Cadeaux",
  "Frais bancaires",
  "Sports",
  "Hobbies",
  "Soins personnels",
  "Événements",
  "Abonnements",
  "Électronique",
  "Animaux de compagnie",
  "Frais médicaux",
  "Frais de scolarité",
  "Impôts",
  "Prêts",
  "Donations",
  "Investissements",
  "Eau et électricité",
];

export const income_suggestions: string[] = [
  "Salaire",
  "Intérêts",
  "Dividendes",
  "Pension",
  "Allocation",
  "Vente",
  "Cadeaux",
  "Remboursement",
  "Prix",
  "Prix de loterie",
  "Investissements",
  "Héritage",
  "Epargne",
];
