import { PieChartFilled } from "@ant-design/icons";
import { Alert, Button, DatePicker, Form, Modal } from "antd";
import React from "react";
import ReactECharts from "echarts-for-react";
import dayjs, { Dayjs } from "dayjs";
import { monthly_option } from "../../data/expenses";
import { TransactionDetail } from "../../types/model";

type Props = {
  data: Array<TransactionDetail>;
};

function MonthlyExpenses({ data }: Props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<Dayjs>(dayjs());
  const [open, setOpen] = React.useState<boolean>(false);

  const onFilter = (date: any, dateString: string) => {
    setFilter(dayjs(dateString));
  };

  return (
    <>
      <Button
        type="text"
        icon={<PieChartFilled style={{ color: "#ca54f9" }} />}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        width={800}
        title={"Détails des dépenses du mois"}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Alert
          message="Toutes les informations affichées ici sont calculées à partir des revenus renseignés dans la liste."
          type="info"
          showIcon
          closable
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px 0",
          }}
        >
          <h3 style={{ margin: 0 }}>Dépenses ce mois</h3>
          {/* <Form
            name="filter-expenses"
            initialValues={{ filter }}
            autoComplete="off"
            form={form}
          >
            <Form.Item label="Filtrer" style={{ margin: 0 }} name="filter">
              <DatePicker
                onChange={onFilter}
                format={"MMMM YYYY"}
                picker="month"
                allowClear={false}
              />
            </Form.Item>
          </Form> */}
        </div>
        <div>
          <ReactECharts
            option={monthly_option(
              data.map((item) => ({
                value: item.value,
                name: item.reason,
              }))
            )}
            notMerge={true}
          />
        </div>
      </Modal>
    </>
  );
}

export default MonthlyExpenses;
