import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import React from "react";
import { Transaction } from "../../model/Transaction";
import {
  CurrencyOptions,
  TypeOptions,
  frequencyOptions,
  rules,
} from "../../constants";
import { useAction } from "../../contexts/ActionContext";
import { createTransaction } from "../../services/TransactionService";
import { useIncome } from "../../contexts/IncomeContext";
import { TransactionFrequency } from "../../types/model";
import { formatIncomeFormData, formatNumber } from "../../utils/data";
import dayjs from "dayjs";
import Suggestions from "../Income/Suggestions";
import { UploadOutlined } from "@ant-design/icons";
import { dummyRequest } from "../../utils/services";

type Props = {};

const { Option } = Select;

const defaultInput: Transaction = {
  active: 1,
  amount: 0,
  frequency: "monthly",
  description: "",
  reason: "",
  datefrequency: dayjs(),
  impot: 0,
  currency: "pourcentage",
  begin_at: dayjs(),
  end_at: undefined,
  pj: null,
  type: "revenu",
};

function AddIncome({}: Props) {
  const [form] = Form.useForm();
  const [isOnce, setIsOnce] = React.useState<boolean>(false);
  const [hasTaxes, setHasTaxes] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { openAddIncome, setOpenAddIncome } = useAction();
  const { reloadData } = useIncome();

  const checkFrequency = (value: TransactionFrequency) => {
    if (value === "once") {
      setIsOnce(true);
    } else {
      setIsOnce(false);
    }
  };

  const onChangeHasTaxes = (value: boolean) => {
    setHasTaxes(value);
  };

  const save = () => {
    setIsLoading(true);
    createTransaction(formatIncomeFormData(form))
      .then((response) => {
        message.success("Revenu ajouté avec succès");
        reloadData();
        setIsOnce(false);
        setHasTaxes(false);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error("Erreur lors de l'ajout du revenu");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title="Ajouter des revenus"
      open={openAddIncome}
      footer={null}
      onCancel={() => setOpenAddIncome(false)}
      okText="Enregistrer"
      cancelText="Annuler"
    >
      <Form
        form={form}
        initialValues={{ ...defaultInput }}
        style={{ marginTop: 22 }}
        layout="vertical"
        name="control-incomes"
        onFinish={save}
      >
        <Form.Item name="reason" label="Source" rules={[rules.requiredField]}>
          <Suggestions form={form} />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Montant (Ariary)"
          rules={[
            rules.requiredField,
            {
              min: 100,
              type: "number",
              message: "La valeur doit être au minimum 100 Ariary",
            },
          ]}
        >
          <InputNumber
            min={100}
            style={{ width: "100%" }}
            formatter={(value) => formatNumber(value as number)}
          />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Fréquence"
          rules={[rules.requiredField]}
          tooltip="L'option Ponctuel signifie appliqué  une fois"
        >
          <Select
            placeholder="Choisir parmis les fréquences"
            allowClear
            onChange={checkFrequency}
          >
            {frequencyOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOnce && (
          <Form.Item
            name="datefrequency"
            label="Appliqué sur quel date?"
            rules={[rules.requiredField]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        )}
        {!isOnce ? (
          <>
            <Form.Item
              name="begin_at"
              label="Sera effective à partir de"
              tooltip="Permet de savoir à quelle date le revenu sera effective"
              rules={[
                rules.requiredField,
                {
                  validator(rule, value) {
                    if (
                      form.getFieldValue("end_at") &&
                      !value.isBefore(form.getFieldValue("end_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être supérieur à la date de fin!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="end_at"
              label="Sera effective jusqu'à"
              tooltip="Permet de savoir jusque quand le revenu sera effective"
              rules={[
                {
                  validator(rule, value) {
                    if (
                      value &&
                      value.isBefore(form.getFieldValue("begin_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être inférieur à la date de début!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                    console.log(value);
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
        <Checkbox
          style={{ marginBottom: 16 }}
          checked={hasTaxes}
          onChange={(e) => onChangeHasTaxes(e.target.checked)}
        >
          Impôts
        </Checkbox>
        {hasTaxes && (
          <>
            <Form.Item
              name="impot"
              label="Impôt à payer"
              rules={[
                rules.requiredField,
                {
                  min: 1,
                  type: "number",
                  message: "La valeur doit être supérieur à 0",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="currency"
              label="Forme de paiement"
              rules={[rules.requiredField]}
            >
              <Select>
                {CurrencyOptions.map((option) => (
                  <Option value={option.value} key={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item name="type" label="Type" rules={[rules.requiredField]}>
          <Select placeholder="Choisir parmis les type" disabled>
            {TypeOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="pj" label="Pièce jointe" valuePropName="pj">
          <Upload
            name="pj"
            multiple={false}
            maxCount={1}
            customRequest={dummyRequest}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Ajouter une pièce jointe</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Button onClick={() => setOpenAddIncome(false)}>Annuler</Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              {isLoading ? "En cours..." : "Enregistrer"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddIncome;
