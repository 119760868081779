import { EChartsOption } from "echarts-for-react";

type PieData = { value: number; name: string };

export const monthly_option: EChartsOption = (data: PieData[]) => {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "Dépense",
        type: "pie",
        radius: "80%",
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
};

export const total_expenses: EChartsOption = (data: PieData[]) => {
  return {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    // legend: {
    //   type: "scroll",
    //   orient: "vertical",
    //   right: 10,
    //   top: 20,
    //   bottom: 20,
    // },
    grid: {
      left: "0%",
    },
    series: [
      {
        name: "Dépense",
        type: "pie",
        // label: {
        //   show: false,
        // },
        radius: "75%",
        center: ["50%", "50%"],
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
};
