import { Setting } from "../model/Setting";
import { User } from "../model/User";
import { create_session } from "../services/UserService";
import { getCookie } from "./services";

export const checkAuth = (cookie: any) => {
  return new Promise((resolve) => {
    if (cookie.token) {
      create_session()
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
};

export const getAuthData = () => {
  let token = "";
  if (document) {
    const cookies = document.cookie;
    if (cookies) {
      token = getCookie("token");
    }
  }

  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const payload = JSON.parse(jsonPayload);
    return payload;
  }
  return null;
};
  

export const getUser = () => {
  const payload = getAuthData();
  if (payload) {
    return payload.user as User;
  }
  return null;
};
  
export const getSetting = () => {
  const payload = getAuthData();
  if (payload) {
    return payload.settings as Setting;
  }
  return null;
};