import React from "react";
import { Space, Switch, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { updateActiveInvestissement } from "../../services/InvestissementService";
import { Investissement } from "../../model/Investissement";
import { useInvestissment } from "../../contexts/InvestissmentContext";

type Props = {
  item: Investissement;
};

function ActiveSwitch({ item }: Props) {
  const { reloadData } = useInvestissment();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onChange = (checked: boolean) => {
    if (item.id) {
      setIsLoading(true);
      updateActiveInvestissement(item.id, checked)
        .then(() => {
          message.success("Ligne mise à jour");
          reloadData();
        })
        .catch(() => {
          message.error("Erreur lors de la mise à jour de la ligne");
        }).finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <Space>
      <Switch
        checked={item.active == 1 ? true : false}
        onChange={onChange}
        disabled={isLoading}
      />
      {
        isLoading && <LoadingOutlined />
      }
    </Space>
  );
}

export default ActiveSwitch;
