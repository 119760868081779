import React from "react";

type ContextType = {
  openAddIncome: boolean;
  setOpenAddIncome: (value: boolean) => void;
  openAddOutcome: boolean;
  setOpenAddOutcome: (value: boolean) => void;
  openAddEconomy: boolean;
  setOpenAddEconomy: (value: boolean) => void;
  openAddInvest: boolean;
  setOpenAddInvest: (value: boolean) => void;
  openSetting: boolean;
  setOpenSetting: (value: boolean) => void;
};

const defaultContext: ContextType = {
  openAddIncome: false,
  setOpenAddIncome: (value: boolean) => {},
  openAddOutcome: false,
  setOpenAddOutcome: (value: boolean) => {},
  openAddEconomy: false,
  setOpenAddEconomy: (value: boolean) => {},
  openAddInvest: false,
  setOpenAddInvest: (value: boolean) => {},
  openSetting: false,
  setOpenSetting: (value: boolean) => {},
};

const ActionContext = React.createContext<ContextType>(defaultContext);

const ActionProvider = ({ children }: { children: React.ReactNode }) => {
  const [openAddIncome, setOpenAddIncome] = React.useState<boolean>(false);
  const [openAddOutcome, setOpenAddOutcome] = React.useState<boolean>(false);
  const [openAddEconomy, setOpenAddEconomy] = React.useState<boolean>(false);
  const [openAddInvest, setOpenAddInvest] = React.useState<boolean>(false);
  const [openSetting, setOpenSetting] = React.useState<boolean>(false);

  return (
    <ActionContext.Provider
      value={{
        openAddIncome,
        setOpenAddIncome,
        openAddOutcome,
        setOpenAddOutcome,
        openAddEconomy,
        setOpenAddEconomy,
        openAddInvest,
        setOpenAddInvest,
        openSetting,
        setOpenSetting,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};

const useAction = () => React.useContext(ActionContext);

export { ActionProvider, useAction };
