import React from "react";
import { Transaction } from "../../model/Transaction";
import { Space, Switch, message } from "antd";
import { updateActiveTransaction } from "../../services/TransactionService";
import { useIncome } from "../../contexts/IncomeContext";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  transaction: Transaction;
};

function ActiveSwitch({ transaction }: Props) {
  const { reloadData } = useIncome();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onChange = (checked: boolean) => {
    if (transaction.id) {
      setIsLoading(true);
      updateActiveTransaction(transaction.id, checked)
        .then(() => {
          message.success("Ligne mise à jour");
          reloadData();
        })
        .catch(() => {
          message.error("Erreur lors de la mise à jour de la ligne");
        }).finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <Space>
      <Switch
        checked={transaction.active == 1 ? true : false}
        onChange={onChange}
        disabled={isLoading}
      />
      {
        isLoading && <LoadingOutlined />
      }
    </Space>
  );
}

export default ActiveSwitch;
