import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  message,
} from "antd";
import React from "react";
import { Investissement } from "../../model/Investissement";
import { rules } from "../../constants";
import { useAction } from "../../contexts/ActionContext";
import { useInvestissment } from "../../contexts/InvestissmentContext";
import { createInvestissement } from "../../services/InvestissementService";
import { InvestissementFrequency } from "../../types/model";
import { formatInvestFormData, formatNumber } from "../../utils/data";
import dayjs from "dayjs";

type Props = {};

const { Option } = Select;

const defaultInput: Investissement = {
  amount: 0,
  description: "",
  frequency: "monthly",
  datefrequency: dayjs(),
  reason: "",
  begin_at: dayjs(),
  end_at: undefined,
};

const fequencyOptions: { label: string; value: InvestissementFrequency }[] = [
  { label: "Journalier", value: "daily" },
  { label: "Hebdomadaire", value: "weekly" },
  { label: "Mensuel", value: "monthly" },
  { label: "Ponctuel", value: "once" },
];

function AddInvest({}: Props) {
  const [form] = Form.useForm();
  const [isOnce, setIsOnce] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { openAddInvest, setOpenAddInvest } = useAction();
  const { reloadData } = useInvestissment();

  const checkFrequency = (value: InvestissementFrequency) => {
    if (value === "once") {
      setIsOnce(true);
    } else {
      setIsOnce(false);
    }
  };

  const save = () => {
    setIsLoading(true);
    createInvestissement(formatInvestFormData(form))
      .then((response) => {
        message.success("Investissement ajouté avec succès");
        reloadData();
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error("Erreur lors de l'ajout de l'investissement");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title="Ajouter un investissement"
      open={openAddInvest}
      onOk={save}
      onCancel={() => setOpenAddInvest(false)}
      footer={null}
    >
      <Form
        form={form}
        initialValues={defaultInput}
        style={{ marginTop: 22 }}
        layout="vertical"
        name="control-invest"
        onFinish={save}
      >
        <Form.Item name="reason" label="Raison" rules={[rules.requiredField]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Montant (Ariary)"
          rules={[
            rules.requiredField,
            {
              min: 100,
              type: "number",
              message: "La valeur doit être au minimum 100 Ariary",
            },
          ]}
        >
          <InputNumber min={100} style={{ width: "100%" }} formatter={(value) => formatNumber(value as number)} />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Fréquence"
          rules={[rules.requiredField]}
          tooltip="L'option Ponctuel signifie appliqué  une fois"
        >
          <Select
            placeholder="Choisir parmis les fréquences"
            allowClear
            onChange={checkFrequency}
          >
            {fequencyOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOnce && (
          <Form.Item
            name="datefrequency"
            label="Appliqué sur quel date?"
            rules={[rules.requiredField]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        )}
        {!isOnce ? (
          <>
            <Form.Item
              name="begin_at"
              label="Sera effective à partir de"
              tooltip="Permet de savoir à quelle date le revenu sera effective"
              rules={[
                rules.requiredField,
                {
                  validator(rule, value) {
                    if (
                      form.getFieldValue("end_at") &&
                      !value.isBefore(form.getFieldValue("end_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être supérieur à la date de fin!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="end_at"
              label="Sera effective jusqu'à"
              tooltip="Permet de savoir jusque quand le revenu sera effective"
              rules={[
                {
                  validator(rule, value) {
                    if (value && value.isBefore(form.getFieldValue("begin_at"))) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être inférieur à la date de début!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                    console.log(value);
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Button onClick={() => setOpenAddInvest(false)}>Annuler</Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading ? "En cours" : "Enregistrer"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddInvest;
