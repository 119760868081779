import React from 'react'

type Props = {}

function Calendar({}: Props) {
  return (
    <div>Calendar</div>
  )
}

export default Calendar