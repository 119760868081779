import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  message,
} from "antd";
import React, { useEffect } from "react";
import { frequencyOptions, rules } from "../../constants";
import dayjs from "dayjs";
import { Investissement } from "../../model/Investissement";
import { useInvestissment } from "../../contexts/InvestissmentContext";
import { updateInvestissement } from "../../services/InvestissementService";
import { InvestissementFrequency } from "../../types/model";
import { formatDateData, formatInvestFormData, formatNumber } from "../../utils/data";

type Props = {
  invest: Investissement;
  open: boolean;
  onClose: () => void;
};

const { Option } = Select;

function EditInvestissement({ invest, open, onClose }: Props) {
  const [form] = Form.useForm();
  const [isOnce, setIsOnce] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { reloadData } = useInvestissment();

  const checkFrequency = (value: InvestissementFrequency) => {
    if (value === "once") {
      setIsOnce(true);
    } else {
      setIsOnce(false);
    }
  };

  const save = () => {
    if (invest.id !== undefined) {
      setIsLoading(true);
      updateInvestissement(invest.id, formatInvestFormData(form))
        .then((response) => {
          message.success("Investissement modifié avec succès");
          reloadData();
        })
        .catch((error) => {
          console.log(error);
          message.error("Erreur lors de la modification de l'investissement");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (invest) {
      form.setFieldsValue({
        ...invest,
        datefrequency: formatDateData(invest.datefrequency),
        begin_at: formatDateData(invest.begin_at),
        end_at: formatDateData(invest.end_at),
      });
      checkFrequency(invest.frequency);
    }
  }, [invest]);

  return (
    <Modal
      title="Modifier les données"
      open={open}
      footer={null}
      onCancel={() => onClose()}
      okText="Modifier"
      cancelText="Annuler"
    >
      <Form
        form={form}
        initialValues={{
          ...invest,
          datefrequency: dayjs(invest.datefrequency),
        }}
        style={{ marginTop: 22 }}
        layout="vertical"
        name="edit-investissement"
        onFinish={save}
      >
        <Form.Item name="reason" label="Raison" rules={[rules.requiredField]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Montant (Ariary)"
          rules={[
            rules.requiredField,
            {
              min: 100,
              type: "number",
              message: "La valeur doit être au minimum 100 Ariary",
            },
          ]}
        >
          <InputNumber min={100} style={{ width: "100%" }} formatter={(value) => formatNumber(value as number)} />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Fréquence"
          rules={[rules.requiredField]}
          tooltip="L'option Ponctuel signifie appliqué une fois"
        >
          <Select
            placeholder="Choisir parmis les fréquences"
            allowClear
            onChange={checkFrequency}
          >
            {frequencyOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOnce && (
          <Form.Item
            name="datefrequency"
            label="Appliqué sur quel date?"
            rules={[rules.requiredField]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        )}
        {!isOnce ? (
          <>
            <Form.Item
              name="begin_at"
              label="Sera effective à partir de"
              tooltip="Permet de savoir à quelle date le revenu sera effective"
              rules={[
                rules.requiredField,
                {
                  validator(rule, value) {
                    if (
                      form.getFieldValue("end_at") &&
                      !value.isBefore(form.getFieldValue("end_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être supérieur à la date de fin!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="end_at"
              label="Sera effective jusqu'à"
              tooltip="Permet de savoir jusque quand le revenu sera effective"
              rules={[
                {
                  validator(rule, value) {
                    if (value && value.isBefore(form.getFieldValue("begin_at"))) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être inférieur à la date de début!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                    console.log(value);
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Button onClick={() => onClose()}>Annuler</Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              {isLoading ? "En cours..." : "Modifier"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditInvestissement;
