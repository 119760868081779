import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Popover, Tag, Tooltip, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import React from "react";
import Tips from "../components/Tips";

import { useAuth } from "../contexts/AuthProvider";
import ProfileMenu from "./ProfileMenu";

type Props = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};

function Nav({ collapsed, setCollapsed }: Props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { user } = useAuth();

  return (
    <Header
      style={{
        padding: "0 30px",
        background: colorBgContainer,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
        }}
      />
      <div style={{ display: "flex", alignItems: "center", gap: 22 }}>
        <Tag icon={<ThunderboltOutlined />} color="default">
          Version Beta
        </Tag>
        <Popover
          placement="bottomLeft"
          title={
            <span style={{ display: "flex", alignItems: "center", gap: 7 }}>
              <i className="fa fa-lightbulb" style={{ color: "#dac712" }}></i>
              Tips
            </span>
          }
          content={<Tips />}
        >
          <Tooltip>
            <Button
              shape="circle"
              icon={
                <i
                  className="far fa-lightbulb"
                  style={{ color: "#454545" }}
                ></i>
              }
            />
          </Tooltip>
        </Popover>

        <Popover
          placement="bottomRight"
          content={<ProfileMenu />}
          trigger="click"
          overlayInnerStyle={{ padding: "5px 10px" }}
        >
          <Avatar
            style={{
              backgroundColor: "#fde3cf",
              color: "#f56a00",
              cursor: "pointer",
            }}
          >
            {user && user.name ? user.name[0] : "M"}
          </Avatar>
        </Popover>
      </div>
    </Header>
  );
}

export default Nav;
