import React from "react";
import { Card, Skeleton } from "antd";
import ReactECharts from "echarts-for-react";
import { total_expenses } from "../../data/expenses";
import { TransactionDetail } from "../../types/model";
import { getTotalOutcomes } from "../../services/TransactionService";

type Props = {};

export default function Expenses({}: Props) {
  const [data, setData] = React.useState<Array<TransactionDetail>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getData = () => {
    setIsLoading(true);
    getTotalOutcomes()
      .then((res) => {
        if (res && res.data) {
          setData(
            Object.keys(res.data).map((key) => {
              return {
                reason: key,
                value: res.data[key],
              };
            })
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Card style={{ marginBottom: 16 }}>
      <div>
        <h3 style={{ marginTop: 0 }}>Total dépenses</h3>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <ReactECharts
            option={total_expenses(
              data.map((item) => ({
                value: item.value,
                name: item.reason,
              }))
            )}
            notMerge={true}
            style={{ height: 450 }}
          />
        )}
      </div>
    </Card>
  );
}

const styles = {
  flex: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  spaced: {
    justifyContent: "space-between",
  },
};
