import { Transaction } from "../model/Transaction";
import { DailyType } from "../types/model";
import { createFormData } from "../utils/services";
import axiosInstance from "./AxiosInstance";

export function getIncomes() {
  return axiosInstance.get(`transactions/incomes`);
}

export function getOutcomes() {
  return axiosInstance.get(`transactions/outcomes`);
}

export function getIncomesStats() {
  return axiosInstance.get(`transactions/incomes-stats`);
}

export function getOutcomesStats() {
  return axiosInstance.get(`transactions/outcomes-stats`);
}

export function getNextSixMonthsIncomes() {
  return axiosInstance.get(`transactions/next-six-month-incomes`);
}

export function getTotalOutcomes() {
  return axiosInstance.get(`transactions/total-outcomes`);
}

export function createTransaction(data: Partial<Transaction>) {
  return axiosInstance.post(`transactions/add`, createFormData(data));
}

export function updateTransaction(
  id: string | number,
  data: Partial<Transaction>
) {
  return axiosInstance.post(`transactions/update/${id}`, createFormData(data));
}

export function updateActiveTransaction(id: string | number, active: boolean) {
  return axiosInstance.post(
    `transactions/update-active/${id}`,
    createFormData({ active: active ? 1 : 0 })
  );
}

export function updateActiveMultipleTransaction(
  ids: string | number,
  active: boolean
) {
  return axiosInstance.post(
    `transactions/activate-multiple`,
    createFormData({ active: active ? 1 : 0, ids })
  );
}

export function updateDailyOptionTransaction(
  id: string | number,
  option: DailyType
) {
  return axiosInstance.post(
    `transactions/update-daily/${id}`,
    createFormData({ daily: option })
  );
}

export function deleteTransaction(id: number) {
  return axiosInstance.post(`transactions/delete/${id}`);
}

export function deleteMultipleTransaction(ids: string | number) {
  return axiosInstance.post(
    `transactions/delete-multiple`,
    createFormData({ ids })
  );
}
