import { Badge, Button, Card, Col, List, Row, Space, theme } from "antd";
import React from "react";

import "../../assets/styles/register.scss";
import { CheckOutlined } from "@ant-design/icons";

type Props = {};

const { green } = theme.defaultConfig.token;

function Pricing({}: Props) {
  return (
    <Row>
      <Col span={24} md={12}>
        <Card>
          <Badge className="pricing-badge basic">Plan basique</Badge>
          <h3 className="pricing-title">2.5 € par mois</h3>
          <span className="package-user-type">Pour un compte personnelle</span>
          <List style={{ marginBottom: 22 }}>
            <List.Item>
              <Space>
                <CheckOutlined style={{ color: green }} />
                <span>20 Transactions</span>
              </Space>
            </List.Item>
            <List.Item>
              <Space>
                <CheckOutlined style={{ color: green }} />
                <span>Unlimited Storage</span>
              </Space>
            </List.Item>
            <List.Item>
              <Space>
                <CheckOutlined style={{ color: green }} />
                <span>Unlimited Storage</span>
              </Space>
            </List.Item>
          </List>
          <Button type="primary" className="pricing-button basic">
            Choisir
          </Button>
        </Card>
      </Col>
    </Row>
  );
}

export default Pricing;
