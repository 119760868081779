import { Breadcrumb, Col, Row, theme } from "antd";
import React from "react";
import Cards from "../components/Dashboard/Cards";
import Incomes from "../components/Dashboard/Incomes";
import Investissment from "../components/Investissment";
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import Expenses from "../components/Dashboard/Expenses";

type Props = {};

const breadcumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
  {
    title: "Accueil",
  },
  {
    title: "Tableau de bord",
  },
];

function Home({}: Props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }} items={breadcumbs} />
      <Row gutter={16}>
        <Col span={24}>
          <Cards />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xxl={12} span={24}>
          <Incomes />
        </Col>
        <Col xxl={12} span={24}>
          <Expenses />
        </Col>
        {/* <Col span={12}>
          <Investissment />
        </Col> */}
      </Row>
    </>
  );
}

export default Home;
