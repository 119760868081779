import React from "react";
import { Investissement } from "../model/Investissement";
import { getInvests } from "../services/InvestissementService";

type Props = { children: React.ReactNode };

type ContextType = {
  invests: Investissement[];
  loading: boolean;
  reloadData: () => void;
};

const defaultContext: ContextType = {
  invests: [],
  loading: true,
  reloadData: () => {},
};

const InvestissmentContext = React.createContext<ContextType>(defaultContext);

const InvestissmentProvider = ({ children }: Props) => {
  const [invests, setInvests] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getData = () => {
    getInvests()
      .then((response) => {
        if (response.data) {
          setInvests(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <InvestissmentContext.Provider
      value={{ invests, loading, reloadData: getData }}
    >
      {children}
    </InvestissmentContext.Provider>
  );
};

const useInvestissment = () => React.useContext(InvestissmentContext);

export { InvestissmentProvider, useInvestissment };
