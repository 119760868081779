import { Breadcrumb, Button, Space } from "antd";
import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useAction } from "../contexts/ActionContext";
import DataTable from "../components/Outcome/DataTable";
import { useOutcome } from "../contexts/OutcomeContext";
import Stats from "../components/Outcome/Stats";
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import ActionMultiple from "../components/Outcome/ActionMultiple";

type Props = {};

const breadcumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
  {
    title: "Dépenses",
  },
  {
    title: "Liste",
  },
];

function Outcomes({}: Props) {
  const { setOpenAddOutcome } = useAction();
  const { reloadData } = useOutcome();

  useEffect(() => {
    reloadData();
  }, []);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }} items={breadcumbs} />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.2rem",
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Liste des dépenses</h2>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenAddOutcome(true)}
            >
              Ajouter des dépenses
            </Button>
            <ActionMultiple />
          </Space>
        </div>
        <Stats />
        <DataTable />
      </div>
    </>
  );
}

export default Outcomes;
