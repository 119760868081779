import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import { Layout, Menu, MenuProps, theme } from "antd";

const { Header, Content, Footer } = Layout;

const GuestLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { isAuthentified } = useAuth();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "login",
      label: "Connexion",
      onClick: () => navigate("/login"),
    },
    {
      key: "register",
      label: "Inscription",
      onClick: () => navigate("/register"),
    },
  ];

  React.useEffect(() => {
    if (isAuthentified) {
      navigate("/admin/dashboard");
    }
  }, [isAuthentified]);

  return (
    <Layout>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["login"]}
          items={items}
        />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div
          className="site-layout-content"
          style={{
            background: colorBgContainer,
            margin: "16px 0",
            padding: 30,
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Monkey Finance ©2023
      </Footer>
    </Layout>
  );
};

export default GuestLayout;
