import { MenuProps } from "antd";
import { MenuType } from "./types";
import { ImpotType, TransactionFrequency, TransactionType } from "./types/model";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const menus: MenuType[] = [
  {
    key: "home",
    label: "Accueil",
  },
  {
    key: "about",
    label: "À propos",
  },
];

export const rules = {
  requiredField: { required: true, message: "Ce champ est requis" }
}

export const frequencyOptions: { label: string; value: TransactionFrequency }[] = [
  { label: "Journalier", value: "daily" },
  { label: "Hebdomadaire", value: "weekly" },
  { label: "Mensuel", value: "monthly" },
  { label: "Ponctuel", value: "once" },
];

export const TypeOptions: { label: string; value: TransactionType }[] = [
  { label: "Revenu", value: "revenu" },
  { label: "Dépense", value: "depense" },
  { label: "Economie", value: "epargne" },
];

export const CurrencyOptions: { label: string; value: ImpotType }[] = [
  { label: "%", value: "pourcentage" },
  { label: "Ariary", value: "montant" },
]