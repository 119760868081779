import React from "react";
import { getOutcomes, getOutcomesStats } from "../services/TransactionService";
import { OutcomeStats, Transaction } from "../model/Transaction";
import { message } from "antd";

type ContextType = {
  outcomes: Transaction[];
  stats: OutcomeStats | null;
  selected: Transaction[];
  loading: boolean;
  reloadData: () => void;
  setSelected: (selected: Transaction[]) => void;
};

const OutcomeContext = React.createContext<ContextType>({
  outcomes: [],
  selected: [],
  stats: null,
  loading: true,
  reloadData: () => {},
  setSelected: (selected: Transaction[]) => {},
});

const OutcomeProvider = ({ children }: { children: React.ReactNode }) => {
  const [outcomes, setOutcomes] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<Transaction[]>([]);
  const [stats, setStats] = React.useState<OutcomeStats | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getData = () => {
    getOutcomes()
      .then((response) => {
        if (response.data) {
          setOutcomes(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentMonth = () => {
    return getOutcomesStats()
      .then((response) => {
        if (response && response.data) {
          setStats(response.data);
        }
      })
      .catch((error) => {
        message.error(
          "Erreur lors de la récupération des depenses du mois courant"
        );
      });
  };

  React.useEffect(() => {
    getCurrentMonth();
  }, [outcomes]);

  return (
    <OutcomeContext.Provider
      value={{
        outcomes,
        selected,
        stats,
        loading,
        reloadData: getData,
        setSelected,
      }}
    >
      {children}
    </OutcomeContext.Provider>
  );
};

const useOutcome = () => React.useContext(OutcomeContext);

export { OutcomeProvider, useOutcome };
