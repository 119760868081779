import { Breadcrumb, Button, Space } from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useAction } from "../contexts/ActionContext";
import { useIncome } from "../contexts/IncomeContext";
import DataTable from "../components/Income/DataTable";
import Stats from "../components/Income/Stats";
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import ActionMultiple from "../components/Income/ActionMultiple";

type Props = {};

const breadcumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
  {
    title: "Revenus",
  },
  {
    title: "Liste",
  },
];

function Incomes({}: Props) {
  const { setOpenAddIncome } = useAction();
  const { reloadData } = useIncome();

  React.useEffect(() => {
    reloadData();
  }, []);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }} items={breadcumbs} />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.2rem",
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Revenus</h2>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenAddIncome(true)}
            >
              Ajouter des revenus
            </Button>
            <ActionMultiple />
          </Space>
        </div>
        <Stats />
        <DataTable />
      </div>
    </>
  );
}

export default Incomes;
