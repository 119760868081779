import React from "react";
import image from "../assets/images/construction.png";

type Props = {};

function Tips({}: Props) {
  return (
    <div>
      <h3 style={{ marginTop: 0, textAlign: "center" }}>En construction</h3>
      <img
        src={image}
        width={120}
        style={{ padding: "20px 50px" }}
        alt="En construction"
      />
    </div>
  );
}

export default Tips;
