import { Avatar, Card, List, MenuProps, Space, theme } from "antd";
import React from "react";
import { useAuth } from "../contexts/AuthProvider";
import { useAction } from "../contexts/ActionContext";
import {
  LoadingOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { getCurrentSolde } from "../services/SoldeService";
import { formatMoney } from "../utils/data";
import { useIncome } from "../contexts/IncomeContext";
import { useOutcome } from "../contexts/OutcomeContext";
import { useSetting } from "../contexts/SettingContext";

type Props = {};

function ProfileMenu({}: Props) {
  const { user, logout } = useAuth();
  const { incomes } = useIncome();
  const { outcomes } = useOutcome();
  const { state } = useSetting();
  const [solde, setSolde] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { setOpenSetting } = useAction();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items = [
    {
      label: "Paramètres",
      icon: <SettingOutlined />,
      onClick: () => {
        setOpenSetting(true);
      },
    },
    {
      label: "Aide",
      icon: <QuestionCircleOutlined />,
    },
    {
      label: "Se deconnecter",
      icon: <LogoutOutlined />,
      onClick: () => {
        logout();
      },
    },
  ];

  const getSolde = () => {
    setIsLoading(true);
    getCurrentSolde()
      .then((res) => {
        if (res && res.data) {
          setSolde(res.data.solde);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getSolde();
  }, [incomes, outcomes, state]);

  return (
    <div>
      <Space
        size={"middle"}
        style={{
          padding: 10,
          background: colorBgContainer,
          borderRadius: 5,
          minWidth: 200,
        }}
      >
        <Avatar>{user && user.name ? user.name[0] : "M"}</Avatar>
        <div>
          <strong>{user && user.name ? user.name : "Monkey User"}</strong>
          <br />
          <span style={{ color: "#696969" }}>
            Solde : {!isLoading ? formatMoney(solde) : <LoadingOutlined />}
          </span>
        </div>
      </Space>
      <List
        size="small"
        dataSource={items}
        renderItem={({ label, icon, onClick }) => (
          <List.Item style={{ cursor: "pointer" }} onClick={onClick}>
            <Space>
              {icon}
              <span>{label}</span>
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
}

export default ProfileMenu;
