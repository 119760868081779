import axios from "axios";
import { LoginPayloadType, SignupPayloadType } from "../types/auth";
import { createFormData } from "../utils/services";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export function authenticate(data: LoginPayloadType) {
  return axiosInstance.post(`login`, createFormData(data));
}

export function create_account(data: SignupPayloadType) {
  return axiosInstance.post(`register`, createFormData(data));
}
