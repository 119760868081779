import React from "react";
import { useCookies } from "react-cookie";
import { checkAuth, getUser } from "../utils/auth";
import { authenticate, create_account } from "../services/AuthService";
import { Spin, message } from "antd";
import { User } from "../model/User";

type ContextType = {
  isAuthentified: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  register: (name: string, email: string, password: string) => Promise<any>;
};

const defaultContext: ContextType = {
  isAuthentified: false,
  user: null,
  login: () => new Promise(() => {}),
  logout: () => {},
  register: () => new Promise(() => {}),
};

const AuthContext = React.createContext(defaultContext);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthentified, setIsAuthentified] = React.useState(false);
  const [isChecking, setIsChecking] = React.useState(true);
  const [user, setUser] = React.useState<User | null>(null);
  const [hasSession, setHasSession] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const login = (email: string, password: string) => {
    return authenticate({ email, password })
      .then((response) => {
        if (response && response.data && response.data.token) {
          setCookie("token", response.data.token, {
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 14),
          });
          setIsAuthentified(true);
        }
      })
      .catch((error) => {
        let msg = "Erreur lors de la connexion";
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          msg = error.response.data.error;
        }
        message.error(msg);
        throw error;
      });
  };

  const logout = () => {
    removeCookie("token");
    setIsAuthentified(false);
  };

  const register = (name: string, email: string, password: string) => {
    return create_account({ name, email, password })
      .then((response) => {
        message.success("Votre compte a été créé");
        if (response && response.data && response.data.token) {
          setCookie("token", response.data.token, {
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 14),
          });
          setIsAuthentified(true);
        }
      })
      .catch((error) => {
        let msg = "Erreur lors de la création du compte";
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          msg = error.response.data.error;
        }
        message.error(msg);
        throw error;
      });
  };

  const value = {
    isAuthentified,
    login,
    logout,
    register,
    user,
  };

  React.useEffect(() => {
    if (!hasSession) {
      checkAuth(cookies)
        .then((response) => {
          if (response) {
            setIsAuthentified(true);
            setHasSession(true);
          } else {
            setIsAuthentified(false);
          }
        })
        .finally(() => {
          setIsChecking(false);
        });
    }
  }, [cookies, hasSession]);

  React.useEffect(() => {
    if (isAuthentified) {
      setUser(getUser());
    }
  }, [isAuthentified]);

  return (
    <AuthContext.Provider value={value}>
      {isChecking ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
