import {
  CheckCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  MinusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal, message, theme } from "antd";
import { MenuProps } from "antd/lib";
import React from "react";
import {
  deleteMultipleTransaction,
  updateActiveMultipleTransaction,
} from "../../services/TransactionService";
import { useOutcome } from "../../contexts/OutcomeContext";

type Props = {};

function ActionMultiple({}: Props) {
  const { reloadData, loading, selected } = useOutcome();
  const { colorPrimary } = theme.defaultConfig.token;
  const [messageApi, contextHolder] = message.useMessage();
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);
  const [removing, setRemoving] = React.useState<boolean>(false);

  const updateState = (status: boolean) => {
    messageApi.loading("Mise à jour en cours...", 0);
    updateActiveMultipleTransaction(
      selected.map((item) => item.id).join(","),
      status
    )
      .then(() => {
        message.success("Mise à jour effectuée avec succès");
        reloadData();
      })
      .catch(() => {
        message.error("Erreur lors de la mise à jour");
      })
      .finally(() => {
        messageApi.destroy();
      });
  };

  const deleteSelected = () => {
    setRemoving(true);
    deleteMultipleTransaction(selected.map((item) => item.id).join(","))
      .then(() => {
        message.success("Suppression effectuée avec succès");
        reloadData();
      })
      .catch(() => {
        message.error("Erreur lors de la suppression");
      })
      .finally(() => {
        setRemoving(false);
        setOpenAlert(false);
      });
  };

  const actions: MenuProps["items"] = [
    {
      label: "Actualiser",
      key: "1",
      icon: <ReloadOutlined style={{ color: colorPrimary }} />,
      onClick: () => {
        reloadData();
      },
    },
    {
      label: "Activer",
      key: "2",
      icon: (
        <CheckCircleOutlined
          style={selected.length > 0 ? { color: colorPrimary } : {}}
        />
      ),
      disabled: selected.length === 0,
      onClick: () => updateState(true),
    },
    {
      label: "Désactiver",
      key: "3",
      icon: (
        <MinusCircleOutlined
          style={selected.length > 0 ? { color: colorPrimary } : {}}
        />
      ),
      disabled: selected.length === 0,
      onClick: () => updateState(false),
    },
    {
      label: "Supprimer",
      key: "4",
      icon: <DeleteOutlined />,
      danger: true,
      disabled: selected.length === 0,
      onClick: () => setOpenAlert(true),
    },
  ];

  return (
    <>
      {contextHolder}
      <Modal
        title={`Supprimer ${
          selected.length > 1
            ? `ces ${selected.length} éléments`
            : "cet élément"
        }?`}
        open={openAlert}
        onOk={deleteSelected}
        onCancel={() => setOpenAlert(false)}
        confirmLoading={removing}
        okText="Supprimer"
        okButtonProps={{ danger: true }}
        cancelText="Annuler"
      >
        <p>Cette action sera irréversible.</p>
      </Modal>
      <Dropdown menu={{ items: actions }} trigger={["click"]}>
        <Button icon={<EllipsisOutlined />} loading={loading}>
          Actions
        </Button>
      </Dropdown>
    </>
  );
}

export default ActionMultiple;
