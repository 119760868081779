import { EChartsOption } from "echarts-for-react";

export const projection: EChartsOption = (
  labels: Array<string>,
  revenus: Array<number>,
  balances: Array<number>
) => ({
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["Revenues", "Balance"],
  },
  calculable: true,
  xAxis: [
    {
      type: "category",
      axisTick: {
        show: false,
      },
      data: labels,
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
  series: [
    {
      name: "Revenues",
      type: "bar",
      data: revenus,
      markPoint: {
        data: [
          { type: "max", name: "Max" },
          { type: "min", name: "Min" },
        ],
      },
    },
    {
      name: "Balance",
      type: "bar",
      data: balances,
      markPoint: {
        data: [
          { type: "max", name: "Max" },
          { type: "min", name: "Min" },
        ],
      },
    },
  ],
});

export const solde_details = (data: {
  solde: number;
  revenues: number;
  depenses: number;
  impots: number;
}) => {
  const colors = ["#5470c6", "#a9df96", "#fac858", "#ee6666ff"];

  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      top: "5%",
    },
    xAxis: {
      type: "category",
      data: ["Solde", "Revenues", "Dépenses", "Impôts"],
      axisTick: { show: false },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [data.solde, data.revenues, data.depenses, data.impots],
        type: "bar",
        itemStyle: {
          color: function(params: any) {
            return colors[params.dataIndex];
          },
        },
      },
    ],
  };
};

export const solde_explained = ({
  labels,
  soldes,
  revenues,
  depenses,
  impots,
}: {
  labels: Array<string>;
  soldes: Array<number>;
  revenues: Array<number>;
  depenses: Array<number>;
  impots: Array<number>;
}) => ({
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    data: ["Solde debut du mois", "Revenues", "Dépenses", "Impôts"],
  },
  xAxis: [
    {
      type: "category",
      axisTick: { show: false },
      data: labels,
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
  series: [
    {
      name: "Solde debut du mois",
      type: "bar",
      stack: "Solde",
      barGap: 0,
      emphasis: {
        focus: "series",
      },
      data: soldes,
    },
    {
      name: "Revenues",
      type: "bar",
      stack: "Solde",
      emphasis: {
        focus: "series",
      },
      data: revenues,
    },
    {
      name: "Dépenses",
      type: "bar",
      stack: "Expense",
      emphasis: {
        focus: "series",
      },
      data: depenses,
    },
    {
      name: "Impôts",
      stack: "Expense",
      type: "bar",
      emphasis: {
        focus: "series",
      },
      data: impots,
    },
  ],
});
