import { Breadcrumb, Button } from "antd";
import React from "react";
import {
  PlusOutlined,
} from "@ant-design/icons";
import { useAction } from "../contexts/ActionContext";
import { useInvestissment } from "../contexts/InvestissmentContext";
import DataTable from "../components/Investissement/DataTable";
import { BreadcrumbItemType, BreadcrumbSeparatorType } from "antd/es/breadcrumb/Breadcrumb";

type Props = {};

const breadcumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
  {
    title: "Investissement",
  },
  {
    title: "Liste",
  },
];

function Investissements({}: Props) {
  const { setOpenAddInvest } = useAction();
  const { reloadData } = useInvestissment();

  React.useEffect(() => {
    reloadData();
  }, []);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }} items={breadcumbs} />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.2rem",
          }}
        >
          <h2 style={{ marginBottom: 0 }}>Liste des investissements</h2>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setOpenAddInvest(true)}
          >
            Investir
          </Button>
        </div>
        <DataTable />
      </div>
    </>
  );
}

export default Investissements;
