import axiosInstance from "./AxiosInstance";

export function getCurrentSolde() {
  return axiosInstance.get(`solde/current`);
}

export function getSixLastMonthSolde() {
  return axiosInstance.get(`solde/six-last-month`);
}

export function getDashboardStats() {
  return axiosInstance.get(`solde/dashboard-stats`);
}
