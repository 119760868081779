import React from "react";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "./contexts/AuthProvider";
import { SettingProvider } from "./contexts/SettingContext";
import Index from "./Main";

import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


function App() {
  return (
    <CookiesProvider>
      <AuthProvider>
        <SettingProvider>
          <Index />
        </SettingProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;
