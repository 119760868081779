import React from 'react'

type Props = {}

function About({}: Props) {
  return (
    <div>About</div>
  )
}

export default About