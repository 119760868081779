import { Setting } from "../model/Setting";
import { createFormData } from "../utils/services";
import axiosInstance from "./AxiosInstance";

export function create_session() {
  return axiosInstance.post(`create-session`);
}

export function updateSetting(data: Setting) {
  return axiosInstance.post(`update-settings`, createFormData(data));
}

export function updateFirstTime() {
  return axiosInstance.post(`update-firsttime`);
}

export function refreshToken(token: string) {
  return axiosInstance.post(
    `refresh-token`,
    createFormData({
      token,
    })
  );
}