import React from "react";
import { Transaction } from "../../model/Transaction";
import { Button, Descriptions, DescriptionsProps, Modal } from "antd";
import {
  asset,
  formatFrequency,
  formatImpot,
  formatMoney,
} from "../../utils/data";
import dayjs from "dayjs";
import { LinkOutlined } from "@ant-design/icons";

type Props = {
  income: Transaction;
  open: boolean;
  onClose: () => void;
};

function ShowIncome({ income, open, onClose }: Props) {
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Source",
      children: income.reason,
    },
    {
      key: "2",
      label: "Montant",
      children: formatMoney(income.amount),
    },
    {
      key: "3",
      label: "Fréquence",
      children: formatFrequency(income, income.frequency, income.datefrequency),
    },
    {
      key: "4",
      label: "Impôt",
      children: (
        <>
          {income.impot && income.currency
            ? formatImpot(income.impot, income.currency, income.amount)
            : "Äucun impôt"}
        </>
      ),
    },
    {
      key: "8",
      label: "Pièce jointe",
      children: (
        <>
          {income.pj && typeof income.pj === "string" ? (
            <a
              href={asset(income.pj)}
              target="_blank"
              style={{ display: "flex", gap: 7, alignItems: "center" }}
            >
              <LinkOutlined />
              <span>Voir la pièce jointe</span>
            </a>
          ) : (
            "Aucune pièce jointe"
          )}
        </>
      ),
    },
  ];

  if (income.frequency !== "once") {
    items.splice(items.length - 1, 0, {
      key: "5",
      label: "Debut d'effet",
      children: (
        <>
          {income.begin_at
            ? dayjs(income.begin_at).format("DD MMMM YYYY")
            : "Aucune date définie"}
        </>
      ),
    });
    items.splice(items.length - 1, 0, {
      key: "6",
      label: "Fin d'effet",
      children: (
        <>
          {income.end_at
            ? dayjs(income.end_at).format("DD MMMM YYYY")
            : "Toujours effective"}
        </>
      ),
    });
  }

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Descriptions title="Détails" layout="vertical" items={items} />
      <div className="ant-descriptions-item-container">
        <span
          style={{ color: "#00000073", display: "block", paddingBottom: 16 }}
        >
          Description
        </span>
        <div>{income.description || "Aucune description"}</div>
      </div>
    </Modal>
  );
}

export default ShowIncome;
