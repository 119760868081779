import React from "react";
import { Transaction } from "../../model/Transaction";
import { Descriptions, DescriptionsProps, Modal } from "antd";
import { asset, formatFrequency, formatMoney } from "../../utils/data";
import dayjs from "dayjs";
import { LinkOutlined } from "@ant-design/icons";

type Props = {
  outcome: Transaction;
  open: boolean;
  onClose: () => void;
};

function ShowOutcome({ outcome, open, onClose }: Props) {
  let items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Raison",
      children: outcome.reason,
    },
    {
      key: "2",
      label: "Montant",
      children: formatMoney(outcome.amount),
    },
    {
      key: "3",
      label: "Fréquence",
      children: formatFrequency(
        outcome,
        outcome.frequency,
        outcome.datefrequency
      ),
    },
    {
      key: "8",
      label: "Pièce jointe",
      children: (
        <>
          {outcome.pj && typeof outcome.pj === "string" ? (
            <a
              href={asset(outcome.pj)}
              target="_blank"
              style={{ display: "flex", gap: 7, alignItems: "center" }}
            >
              <LinkOutlined />
              <span>Voir la pièce jointe</span>
            </a>
          ) : (
            "Aucune pièce jointe"
          )}
        </>
      ),
    },
  ];

  if (outcome.frequency !== "once") {
    items.splice(items.length - 1, 0, {
      key: "5",
      label: "Debut d'effet",
      children: (
        <>
          {outcome.begin_at
            ? dayjs(outcome.begin_at).format("DD MMMM YYYY")
            : "Aucune date définie"}
        </>
      ),
    });
    items.splice(items.length - 1, 0, {
      key: "6",
      label: "Fin d'effet",
      children: (
        <>
          {outcome.end_at
            ? dayjs(outcome.end_at).format("DD MMMM YYYY")
            : "Toujours effective"}
        </>
      ),
    });
  }

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Descriptions title="Détails" layout="vertical" items={items} />
      <div className="ant-descriptions-item-container">
        <span
          style={{ color: "#00000073", display: "block", paddingBottom: 16 }}
        >
          Description
        </span>
        <div>{outcome.description || "Aucune description"}</div>
      </div>
    </Modal>
  );
}

export default ShowOutcome;
