import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Space } from "antd";
import React from "react";
import IncomeProjection from "./IncomeProjection";
import Solde from "./Solde";
import { useIncome } from "../../contexts/IncomeContext";
import { formatMoney } from "../../utils/data";
import dayjs from "dayjs";

type Props = {};

function Stats({}: Props) {
  const { stats } = useIncome();
  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      {stats !== null ? (
        <>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Solde actuel"}
              extra={
                <Solde
                  depenses={stats.current_solde.depense}
                  impots={stats.current_solde.impot}
                  revenues={stats.current_solde.revenu}
                  solde={stats.current_solde.last_solde}
                />
              }
            >
              <p style={styles.amount}>
                {formatMoney(stats.current_solde.solde)}
              </p>
              <div>
                <span style={{ color: "#898989" }}>
                  {`Ce jour du ${dayjs().format("DD MMM YYYY")}`}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Solde prévu au début du prochain mois"}
            >
              <p style={styles.amount}>
                {formatMoney(stats.total_month_solde)}
              </p>
              <div>
                <span style={{ color: "#898989" }}>
                  le {dayjs().add(1, "month").format("DD MMM YYYY")}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Revenues ce mois"}
              extra={<IncomeProjection />}
            >
              <p style={styles.amount}>
                {formatMoney(stats.total_current_month)}
              </p>
              <div>
                <Space
                  size={"small"}
                  style={{
                    color: "#f5222d",
                    fontSize: "1.1rem",
                    marginRight: 10,
                  }}
                >
                  <ArrowDownOutlined />
                  <span>10%</span>
                </Space>
                <span style={{ color: "#898989" }}>
                  par rapport au mois dernier
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Impôts à payer ce mois"}
              // extra={<Button type="text" icon={<RiseOutlined />} />}
            >
              <p style={styles.amount}>{formatMoney(stats.month_impot)}</p>
              <div>
                <Space
                  size={"small"}
                  style={{
                    color: "#f5222d",
                    fontSize: "1.1rem",
                    marginRight: 10,
                  }}
                >
                  <ArrowUpOutlined />
                  <span>10%</span>
                </Space>
                <span style={{ color: "#898989" }}>
                  par rapport au mois dernier
                </span>
              </div>
            </Card>
          </Col>
        </>
      ) : (
        <Col span={24}>
          <Card>
            <Skeleton active />
          </Card>
        </Col>
      )}
    </Row>
  );
}

export default Stats;

const styles = {
  amount: {
    fontSize: "2rem",
    fontWeight: 600,
    margin: 0,
  },
};
