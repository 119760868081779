import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import React from "react";
import { Transaction } from "../../model/Transaction";
import { rules } from "../../constants";
import { useAction } from "../../contexts/ActionContext";
import { createTransaction } from "../../services/TransactionService";
import { useOutcome } from "../../contexts/OutcomeContext";
import Suggestions from "../Outcome/Suggestions";
import { TransactionFrequency, TransactionType } from "../../types/model";
import { formatNumber, formatOutcomeFormData } from "../../utils/data";
import dayjs from "dayjs";
import { dummyRequest } from "../../utils/services";
import { UploadOutlined } from "@ant-design/icons";

type Props = {};

const { Option } = Select;

const defaultInput: Transaction = {
  active: 1,
  amount: 0,
  frequency: "monthly",
  description: "",
  reason: "",
  datefrequency: dayjs(),
  type: "depense",
  begin_at: dayjs(),
  pj: null,
  end_at: undefined,
};

const fequencyOptions: { label: string; value: TransactionFrequency }[] = [
  { label: "Journalier", value: "daily" },
  { label: "Hebdomadaire", value: "weekly" },
  { label: "Mensuel", value: "monthly" },
  { label: "Ponctuel", value: "once" },
];

const TypeOptions: { label: string; value: TransactionType }[] = [
  { label: "Revenu", value: "revenu" },
  { label: "Dépense", value: "depense" },
  { label: "Economie", value: "epargne" },
];

function AddOutcome({}: Props) {
  const [form] = Form.useForm();
  const [isOnce, setIsOnce] = React.useState<boolean>(false);
  const { openAddOutcome, setOpenAddOutcome } = useAction();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { reloadData } = useOutcome();

  const checkFrequency = (value: TransactionFrequency) => {
    if (value === "once") {
      setIsOnce(true);
    } else {
      setIsOnce(false);
    }
  };

  const save = () => {
    setIsLoading(true);
    createTransaction(formatOutcomeFormData(form))
      .then((response) => {
        message.success("Dépense enregistrée avec succès");
        reloadData();
        form.resetFields();
        setIsOnce(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Erreur lors de l'ajout de dépense");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title="Ajouter des charges"
      open={openAddOutcome}
      footer={null}
      onCancel={() => setOpenAddOutcome(false)}
      okText="Enregistrer"
      cancelText="Annuler"
    >
      <Form
        form={form}
        initialValues={{ ...defaultInput }}
        style={{ marginTop: 22 }}
        layout="vertical"
        name="control-outcomes"
        onFinish={save}
      >
        <Form.Item name="reason" label="Raison" rules={[rules.requiredField]}>
          <Suggestions form={form} />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Montant (Ariary)"
          rules={[
            rules.requiredField,
            {
              min: 100,
              type: "number",
              message: "La valeur doit être au minimum 100 Ariary",
            },
          ]}
        >
          <InputNumber
            min={100}
            style={{ width: "100%" }}
            formatter={(value) => formatNumber(value as number)}
          />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Fréquence"
          rules={[rules.requiredField]}
          tooltip="L'option Ponctuel signifie appliqué  une fois"
        >
          <Select
            placeholder="Choisir parmis les fréquences"
            allowClear
            onChange={checkFrequency}
          >
            {fequencyOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOnce && (
          <Form.Item
            name="datefrequency"
            label="Appliqué sur quel date?"
            rules={[rules.requiredField]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        )}
        {!isOnce ? (
          <>
            <Form.Item
              name="begin_at"
              label="Sera effective à partir de"
              tooltip="Permet de savoir à quelle date le revenu sera effective"
              rules={[
                rules.requiredField,
                {
                  validator(rule, value) {
                    if (
                      form.getFieldValue("end_at") &&
                      !value.isBefore(form.getFieldValue("end_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être supérieur à la date de fin!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="end_at"
              label="Sera effective jusqu'à"
              tooltip="Permet de savoir jusque quand le revenu sera effective"
              rules={[
                {
                  validator(rule, value) {
                    if (
                      value &&
                      value.isBefore(form.getFieldValue("begin_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être inférieur à la date de début!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                    console.log(value);
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
        <Form.Item name="type" label="Type" rules={[rules.requiredField]}>
          <Select placeholder="Choisir parmis les type" disabled>
            {TypeOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="pj" label="Pièce jointe" valuePropName="pj">
          <Upload
            name="pj"
            multiple={false}
            maxCount={1}
            customRequest={dummyRequest}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Ajouter une pièce jointe</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Button onClick={() => setOpenAddOutcome(false)}>Annuler</Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              {// @ts-ignore
              isLoading ? "En cours" : "Enregistrer"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddOutcome;
