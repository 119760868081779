import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect } from "react";
import { Transaction } from "../../model/Transaction";
import { TypeOptions, frequencyOptions, rules } from "../../constants";
import { updateTransaction } from "../../services/TransactionService";
import dayjs from "dayjs";
import { useOutcome } from "../../contexts/OutcomeContext";
import Suggestions from "./Suggestions";
import { TransactionFrequency } from "../../types/model";
import {
  asset,
  formatDateData,
  formatNumber,
  formatOutcomeFormData,
} from "../../utils/data";
import { dummyRequest } from "../../utils/services";
import { UploadOutlined } from "@ant-design/icons";

type Props = {
  outcome: Transaction;
  open: boolean;
  onClose: () => void;
};

const { Option } = Select;

function EditOutcome({ outcome, open, onClose }: Props) {
  const [form] = Form.useForm();
  const [isOnce, setIsOnce] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { reloadData } = useOutcome();

  const checkFrequency = (value: TransactionFrequency) => {
    if (value === "once") {
      setIsOnce(true);
    } else {
      setIsOnce(false);
    }
  };

  const save = () => {
    if (outcome.id !== undefined) {
      setIsLoading(true);
      updateTransaction(outcome.id, formatOutcomeFormData(form))
        .then((response) => {
          message.success("Dépense modifié avec succès");
          reloadData();
        })
        .catch((error) => {
          console.log(error);
          message.error("Erreur lors de la modification de la dépense");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (outcome) {
      form.setFieldsValue({
        ...outcome,
        datefrequency: formatDateData(outcome.datefrequency),
        begin_at: formatDateData(outcome.begin_at),
        end_at: formatDateData(outcome.end_at),
      });
      checkFrequency(outcome.frequency);
      if (outcome.pj && typeof outcome.pj === "string") {
        const ext = outcome.pj.split(".").pop();
        setFiles([
          {
            uid: "-1",
            name: `PJ.${ext}`,
            status: "done",
            url: asset(outcome.pj),
          },
        ]);
      } else {
        setFiles([]);
      }
    }
  }, [outcome]);

  return (
    <Modal
      title="Modifier la dépense"
      open={open}
      footer={null}
      onCancel={() => onClose()}
      okText="Modifier"
      cancelText="Annuler"
    >
      <Form
        form={form}
        initialValues={{
          ...outcome,
          datefrequency: dayjs(outcome.datefrequency),
        }}
        style={{ marginTop: 22 }}
        layout="vertical"
        name="edit-outcomes"
        onFinish={save}
      >
        <Form.Item name="reason" label="Raison" rules={[rules.requiredField]}>
          <Suggestions form={form} />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Montant (Ariary)"
          rules={[
            rules.requiredField,
            {
              min: 100,
              type: "number",
              message: "La valeur doit être au minimum 100 Ariary",
            },
          ]}
        >
          <InputNumber
            min={100}
            style={{ width: "100%" }}
            formatter={(value) => formatNumber(value as number)}
          />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Fréquence"
          rules={[rules.requiredField]}
          tooltip="L'option Ponctuel signifie appliqué  une fois"
        >
          <Select
            placeholder="Choisir parmis les fréquences"
            allowClear
            onChange={checkFrequency}
          >
            {frequencyOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOnce && (
          <Form.Item
            name="datefrequency"
            label="Appliqué sur quel date?"
            rules={[rules.requiredField]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        )}
        {!isOnce ? (
          <>
            <Form.Item
              name="begin_at"
              label="Sera effective à partir de"
              tooltip="Permet de savoir à quelle date le revenu sera effective"
              rules={[
                rules.requiredField,
                {
                  validator(rule, value) {
                    if (
                      form.getFieldValue("end_at") &&
                      !value.isBefore(form.getFieldValue("end_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être supérieur à la date de fin!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="end_at"
              label="Sera effective jusqu'à"
              tooltip="Permet de savoir jusque quand le revenu sera effective"
              rules={[
                {
                  validator(rule, value) {
                    if (
                      value &&
                      value.isBefore(form.getFieldValue("begin_at"))
                    ) {
                      return Promise.reject(
                        new Error(
                          "Cette date ne doit pas être inférieur à la date de début!"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                    console.log(value);
                  },
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
        <Form.Item name="type" label="Type" rules={[rules.requiredField]}>
          <Select placeholder="Choisir parmis les type" disabled>
            {TypeOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="pj" label="Pièce jointe" valuePropName="pj">
          <Upload
            name="pj"
            multiple={false}
            maxCount={1}
            fileList={files}
            customRequest={dummyRequest}
            onChange={(info) => {
              setFiles(info.fileList);
            }}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Ajouter une pièce jointe</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Button onClick={() => onClose()}>Annuler</Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              {isLoading ? "En cours..." : "Modifier"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditOutcome;
