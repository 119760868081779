import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "../layout/Layout";
import Home from "../pages/Home";
import Incomes from "../pages/Incomes";
import Outcomes from "../pages/Outcomes";
import Investissements from "../pages/Investissements";
import Calendar from "../pages/Calendar";
import About from "../pages/About";
import { useAuth } from "../contexts/AuthProvider";
import Landing from "../pages/Landing";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Steppers from "../pages/Auth/Steppers";
import GuestLayout from "../layout/GuestLayout";
import { ConfigProvider } from "antd";

import locale from "antd/locale/fr_FR";

import "dayjs/locale/fr";

import "../assets/styles/main.scss";

type Props = {};

function Index({}: Props) {
  const { isAuthentified } = useAuth();

  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: "#8c33ef",
        },
      }}
      locale={locale}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/admin/" element={<AppLayout />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="incomes" element={<Incomes />} />
            <Route path="outcomes" element={<Outcomes />} />
            <Route path="invests" element={<Investissements />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="about" element={<About />} />
          </Route>
          <Route path="create-account" element={<Steppers />} />
          <Route path="/" element={<GuestLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default Index;
