import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Space } from "antd";
import React from "react";
import MonthlyExpenses from "./MonthlyExpenses";
import { useOutcome } from "../../contexts/OutcomeContext";
import { formatMoney } from "../../utils/data";

type Props = {};

function Stats({}: Props) {
  const { stats } = useOutcome();

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      {stats !== null ? (
        <>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Dépenses depuis le début du mois"}
              extra={
                <MonthlyExpenses data={stats.current_month.details} />
              }
            >
              <p style={styles.amount}>
                {formatMoney(stats.current_month.total)}
              </p>
              <div>
                <span style={{ color: "#898989" }}>
                  dépensés jusqu'à présent
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Dépenses ce mois"}
              extra={
                <MonthlyExpenses data={stats.total_current_month.details} />
              }
            >
              <p style={styles.amount}>
                {formatMoney(stats.total_current_month.total)}
              </p>
              <div>
                <span style={{ color: "#898989" }}>
                  de dépense prévu ce mois
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Total dépenses fixes mensuels"}
              // extra={<Button type="text" icon={<RiseOutlined />} />}
            >
              <p style={styles.amount}>
                {formatMoney(stats.monthly_expenses)}
              </p>
              <div>
                <span style={{ color: "#898989" }}>
                  à preparer mensuellement
                </span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ width: "100%" }}
              title={"Total dépenses journaliers"}
              // extra={<Button type="text" icon={<RiseOutlined />} />}
            >
              <p style={styles.amount}>
                {formatMoney(stats.daily_expenses)}
              </p>
              <div>
                <span style={{ color: "#898989" }}>
                  à preparer quotidiennement
                </span>
              </div>
            </Card>
          </Col>
        </>
      ) : (
        <Col span={24}>
          <Card>
            <Skeleton active />
          </Card>
        </Col>
      )}
    </Row>
  );
}

export default Stats;

const styles = {
  amount: {
    fontSize: "2rem",
    fontWeight: 600,
    margin: 0,
  },
};
