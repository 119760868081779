import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, message } from "antd";
import React from "react";
import { Transaction } from "../../model/Transaction";
import { deleteTransaction } from "../../services/TransactionService";
import { useOutcome } from "../../contexts/OutcomeContext";

type Props = {
  transaction: Transaction;
  show: (transaction: Transaction) => void;
  edit: (transaction: Transaction) => void;
};

function Actions({ transaction, show, edit }: Props) {
  const { reloadData } = useOutcome();
  const [isRemoving, setIsRemoving] = React.useState<boolean>(false);

  const remove = (id: number) => {
    setIsRemoving(true);
    deleteTransaction(id).then(() => {
      reloadData();
      setIsRemoving(false);
      message.success("Ligne supprimée");
    });
  };

  return (
    <Space size="small">
      <Button
        type="default"
        icon={<EyeOutlined />}
        size={"middle"}
        onClick={() => show(transaction)}
      />
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={"middle"}
        onClick={() => edit(transaction)}
      />
      <Popconfirm
        title="Supprimer la ligne ?"
        description="Cette action est irréversible."
        onConfirm={() => transaction.id && remove(transaction.id)}
        okText="Oui"
        cancelText="Annuler"
        okButtonProps={{ danger: true }}
      >
        <Button
          type="default"
          danger
          icon={<DeleteOutlined />}
          size={"middle"}
          loading={isRemoving}
        />
      </Popconfirm>
    </Space>
  );
}

export default Actions;
