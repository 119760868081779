import React, { Dispatch } from "react";
import { Setting } from "../model/Setting";
import { getSetting } from "../utils/auth";
import { useAuth } from "./AuthProvider";
import { useCookies } from "react-cookie";

type ContextType = {
  state: Setting;
  dispatch: Dispatch<any>;
};

type Actions = "update";

type ReducerType = {
  payload: Partial<Setting>;
  type: Actions;
};

const defaultSetting: Setting = {
  currency: "AR",
  lang: "fr",
  solde: 0,
  theme: "light",
};

const defaultContext: ContextType = {
  state: defaultSetting,
  dispatch: () => null,
};

const reducer = (state: Setting, action: ReducerType) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const SettingContext = React.createContext(defaultContext);

const SettingProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultSetting);
  const { isAuthentified } = useAuth();
  const [cookies] = useCookies();

  React.useEffect(() => {
    const setting = getSetting();
    if (setting) {
      dispatch({ type: "update", payload: setting });
    }
  }, [cookies, isAuthentified]);

  return (
    <SettingContext.Provider value={{ state, dispatch }}>
      {children}
    </SettingContext.Provider>
  );
};

const useSetting = () => React.useContext(SettingContext);

export { SettingProvider, useSetting };
