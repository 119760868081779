import React from "react";
import { Descriptions, DescriptionsProps, Modal } from "antd";
import { formatFrequency, formatMoney } from "../../utils/data";
import { Investissement } from "../../model/Investissement";
import dayjs from "dayjs";

type Props = {
  item: Investissement;
  open: boolean;
  onClose: () => void;
};

function ShowInvestissement({ item, open, onClose }: Props) {
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Raison",
      children: item.reason,
    },
    {
      key: "2",
      label: "Montant",
      children: formatMoney(item.amount),
    },
    {
      key: "3",
      label: "Fréquence",
      children: formatFrequency(item, item.frequency, item.datefrequency),
    },
    {
      key: "7",
      label: "Description",
      children: item.description,
    },
  ];

  if (item.frequency !== "once") {
    items.splice(items.length - 1, 0, {
      key: "5",
      label: "Debut d'effet",
      children: (
        <>
          {item.begin_at
            ? dayjs(item.begin_at).format("DD MMMM YYYY")
            : "Aucune date définie"}
        </>
      ),
    });
    items.splice(items.length - 1, 0, {
      key: "6",
      label: "Fin d'effet",
      children: (
        <>
          {item.end_at
            ? dayjs(item.end_at).format("DD MMMM YYYY")
            : "Toujours effective"}
        </>
      ),
    });
  }

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Descriptions title="Détails" layout="vertical" items={items} />
    </Modal>
  );
}

export default ShowInvestissement;
