import { AutoComplete, FormInstance } from "antd";
import React from "react";
import { outcome_suggestions } from "../../data/suggestions";

type Props = {
  form: FormInstance<any>;
};

function Suggestions({ form }: Props) {
  const [options, setOptions] = React.useState<{ value: string }[]>(
    outcome_suggestions.map((option) => ({
      value: option,
    }))
  );

  const handleChange = (value: string) => {
    form.setFieldsValue({ reason: value });
  };

  return (
    <AutoComplete
      options={options}
      onChange={handleChange}
      onSearch={(text) =>
        setOptions(
          outcome_suggestions
            .map((option) => ({
              value: option,
            }))
            .filter((option) =>
              option.value.toLowerCase().includes(text.toLowerCase())
            )
        )
      }
      value={form.getFieldValue("reason")}
      placeholder="Raison"
    />
  );
}

export default Suggestions;
