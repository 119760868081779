import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import React from "react";

type Props = {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
};

function SearchFilter({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: Props) {
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        placeholder={`Rechercher`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Réinit.
        </Button>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
      </Space>
    </div>
  );
}

export default SearchFilter;
